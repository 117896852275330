import { useEffect } from "react";
import { useFirebase } from "../firebase/context";
import isToday from "date-fns/isToday";

export const isPastTaskUnfinished = (task) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of today
  const dueOnDay = new Date(task.dueOnDay);
  const isTodayDate = isToday(task.dueOnDay);

  return (
    (dueOnDay < today || (isTodayDate && task.itWasInTheFuture)) &&
    !task.checked
  );
};

const checkForYesterdayOrOlderTask = (tasks) =>
  tasks.some((task) => isPastTaskUnfinished(task));

const CheckIfHasYesterdayTask = ({ children }) => {
  const { setHasYesterdayTask, tasks, pullContent } = useFirebase();

  const runTaskCheck = () => {
    setHasYesterdayTask(checkForYesterdayOrOlderTask(tasks));
  };

  useEffect(() => {
    // Initial check when the component mounts
    runTaskCheck();

    // Handle tab becoming visible
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        runTaskCheck();
        pullContent();
      }
    };

    // Handle window/tab gaining focus (e.g., user switches back to app)
    const handleFocus = () => {
      runTaskCheck();
      pullContent();
    };

    // Add event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleFocus);

    // Cleanup
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, setHasYesterdayTask]);

  return children;
};

export default CheckIfHasYesterdayTask;

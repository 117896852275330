import React from "react";
import { Camera, Sparkles, Star, Trophy } from "lucide-react";

const EmptyWinsScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 mt-4 mb-5">
      <div className="text-center max-w-md">
        {/* Enhanced illustration */}
        <div className="mb-8 relative w-48 h-32 mx-auto">
          {/* Main circle with camera */}
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-20 h-20 relative">
              <div className="absolute inset-0 bg-blue-50 rounded-full flex items-center justify-center">
                <Camera className="w-10 h-10 text-blue-400" />
              </div>
              <Trophy className="absolute -right-2 -top-2 w-6 h-6 text-yellow-400" />
            </div>
          </div>

          {/* Left side decorative elements */}
          <div className="absolute left-4 top-1/3">
            <div className="space-y-2">
              <div className="w-2 h-2 bg-rose-100 rounded-full" />
              <div className="w-3 h-3 bg-purple-100 rounded-full" />
              <div className="w-2 h-2 bg-green-100 rounded-full" />
            </div>
          </div>

          {/* Right side decorative elements */}
          <div className="absolute right-4 top-1/3">
            <div className="space-y-2">
              <div className="w-3 h-3 bg-yellow-100 rounded-full" />
              <div className="w-2 h-2 bg-blue-100 rounded-full" />
              <Star className="w-4 h-4 text-rose-200" />
            </div>
          </div>

          {/* Extra floating elements */}
          <div className="absolute left-10 top-2">
            <Sparkles className="w-4 h-4 text-purple-200" />
          </div>
          <div className="absolute right-12 bottom-0">
            <div className="w-2 h-2 bg-yellow-200 rounded-full" />
          </div>
        </div>

        {/* Text content */}
        <h2 className="text-2xl font-semibold text-gray-900 mb-3">
          Celebrate Your Daily Wins
        </h2>
        <p className="text-gray-600 mb-4">
          Capture and commemorate your achievements, no matter how big or small.
        </p>
        <p className="text-gray-600 mb-8">
          Tap the + button to add today's win with a photo!
        </p>

        {/* Additional info */}
        <div className="text-sm text-gray-500">
          Photos help you remember these special moments
        </div>
      </div>
    </div>
  );
};

export default EmptyWinsScreen;

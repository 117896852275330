import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Calendar, Info, MoreVertical } from "lucide-react";
import { useFirebase } from "../firebase/context";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import DeleteModal from "./delete-modal";
import EmptyGoalsScreen from "./empty-goals-screen";
import { countFutureTasksByGoal } from "../utils";
import { UTILITIES_GOAL } from "../utils/constants";
import FutureTasksModal from "./modal-future-tasks";

const Goals = () => {
  const [deletionGoalId, setDeletionGoalId] = useState(null);
  const {
    goals,
    tasks,
    updateAllGoalsInFirebase,
    openDrawerWithGoal,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isFutureTasksModal,
    setGoalForFutureTasks,
    toggleFutureTasksModal,
  } = useFirebase();

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(goals);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateAllGoalsInFirebase(items);
  };

  if (!goals.length) {
    return <EmptyGoalsScreen />;
  }

  const goalsCount = countFutureTasksByGoal(goals, tasks);

  return (
    <div>
      <div className="border-l-4 border-amber-500 pl-4 mb-6">
        <h3 className="text-lg font-medium mb-2">
          Your goals are listed in priority order
        </h3>
        <p className="text-gray-600 text-sm">
          Drag and drop to adjust their priority
        </p>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {goals.map((goal, index) => (
                <Draggable
                  key={String(goal.id)}
                  draggableId={String(goal.id)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`flex items-start justify-between cursor-grab ${
                        snapshot.isDragging
                          ? "border-dotted border-2 border-gray-300"
                          : ""
                      }`}
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? "#f9fafb"
                          : "transparent",
                        ...provided.draggableProps.style,
                      }}
                    >
                      <div className="flex items-start">
                        <div className="pt-5 py-4 pr-4 pl-1">
                          <div
                            className="w-4 h-4 rounded-full"
                            style={{ backgroundColor: goal.color }}
                          />
                        </div>

                        <span className="font-medium py-4">{goal.title}</span>
                      </div>
                      <div className="flex items-start ml-1">
                        {Boolean(goalsCount[goal.id]) && (
                          <button
                            className="flex items-center space-x-2 px-3 mt-4 py-1 rounded-full bg-blue-50 hover:bg-blue-100 text-blue-600 cursor-pointer transition-colors duration-150"
                            onClick={() => {
                              setGoalForFutureTasks(goal.id);
                              toggleFutureTasksModal(true);
                            }}
                          >
                            <span className="text-sm font-medium">
                              {goalsCount[goal.id]}
                            </span>
                            <Calendar size={16} />
                          </button>
                        )}
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger className="py-4 cursor-pointer outline-none pl-3 p-2 -mr-2">
                            <MoreVertical className="text-gray-400" size={23} />
                          </DropdownMenu.Trigger>

                          <DropdownMenu.Portal>
                            <DropdownMenu.Content
                              className="min-w-[180px] bg-white rounded-lg shadow-lg border border-gray-100 -mt-2 mr-1 py-1 -mt-4 z-10"
                              sideOffset={5}
                              align="end"
                            >
                              <DropdownMenu.Item
                                className="flex px-3 py-2 text-normal hover:bg-gray-100 cursor-pointer outline-none"
                                onClick={() => openDrawerWithGoal(goal.id)}
                              >
                                Edit
                              </DropdownMenu.Item>
                              <DropdownMenu.Item
                                className="flex px-3 py-2 text-normal hover:bg-gray-100 cursor-pointer outline-none"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setDeletionGoalId(goal.id);
                                }}
                              >
                                Delete
                              </DropdownMenu.Item>
                            </DropdownMenu.Content>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Root>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-between">
        <div className="flex items-start">
          <div className="pt-5 py-4 pr-4 pl-1">
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: UTILITIES_GOAL.color }}
            />
          </div>

          <span className="font-medium py-4">{UTILITIES_GOAL.title}</span>
        </div>
        <div className="flex items-start ml-1">
          {Boolean(goalsCount.utilities) && (
            <button
              className="flex items-center space-x-2 px-3 mt-4 py-1 rounded-full bg-blue-50 hover:bg-blue-100 text-blue-600 cursor-pointer transition-colors duration-150"
              onClick={() => {
                setGoalForFutureTasks(UTILITIES_GOAL.id);
                toggleFutureTasksModal(true);
              }}
            >
              <span className="text-sm font-medium">
                {goalsCount.utilities}
              </span>
              <Calendar size={16} />
            </button>
          )}
          <DropdownMenu.Root>
            <DropdownMenu.Trigger className="py-4 cursor-pointer outline-none pl-3 p-2 -mr-2 mt-[2px]">
              <Info className="text-gray-400" size={23} />
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="min-w-[180px] bg-white rounded-lg shadow-lg border border-gray-100 -mt-2 mr-1 py-1 -mt-4"
                sideOffset={5}
                align="end"
              >
                <div className="py-2 px-4">
                  This item cannot be edited,
                  <br />
                  deleted or reordered.
                </div>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      </div>
      <DeleteModal
        type="goal"
        setDeletionGoalId={setDeletionGoalId}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        deletionGoalId={deletionGoalId}
      />
      {isFutureTasksModal && <FutureTasksModal />}
    </div>
  );
};

export default Goals;

import React from "react";
import { Star, AlignLeft } from "lucide-react";
import UploadModal from "./upload-modal";
import { formatDateToDayMonth } from "../utils";
import { useFirebase } from "../firebase/context";
import EmptyWinsScreen from "./empty-wins-screen";

const DailyAchievements = () => {
  const { wins, setSelectedWin, toggleWinModal } = useFirebase();

  const sortedWins = [...wins].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <div>
      {/* Grid Layout */}
      {Boolean(sortedWins.length === 0) && <EmptyWinsScreen />}
      {!Boolean(sortedWins.length === 0) && (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
          {sortedWins.map((win) => (
            <div
              className="relative aspect-square rounded-3xl overflow-hidden group cursor-pointer"
              onClick={() => {
                setSelectedWin(win);
                toggleWinModal(true);
              }}
            >
              {win.url ? (
                <>
                  <img
                    src={win.url}
                    alt={win.description}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-transparent to-transparent opacity-100 group-hover:opacity-100 transition-opacity">
                    <div className="absolute bottom-3 left-4 right-3 text-white flex justify-between items-center">
                      <div className="text-sm font-semibold">
                        {formatDateToDayMonth(win.date)}
                      </div>
                      {win.description && (
                        <AlignLeft className="w-4 h-4 text-white opacity-75" />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="w-full h-full bg-gradient-to-br from-blue-600 to-blue-300 p-4 flex flex-col">
                  <Star className="w-6 h-6 mb-2 text-yellow-300 shrink-0" />
                  <div className="flex flex-col justify-between flex-grow min-h-0">
                    <p className="text-xs text-white line-clamp-4 mb-2">
                      {win.description}
                    </p>
                    <div className="text-xs font-semibold text-white/90 shrink-0">
                      {formatDateToDayMonth(win.date)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <UploadModal />
    </div>
  );
};

export default DailyAchievements;

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Goals from "./components/goals";
import Header from "./components/header";
import Today from "./components/today";
import DailyWins from "./components/daily-wins";
import LoginPage from "./components/login-page";
import Drawer from "./components/drawer";
import { FirebaseProvider, useFirebase } from "./firebase/context";
import CheckIfHasYesterdayTask from "./components/check-if-has-yesterday-task";
import AccountPage from "./components/account-page";
import LandingPage from "./components/front-page";
import CheckAuth from "./components/check-auth";
import { authedPaths } from "./utils/constants";

const LoadingSkeleton = () => {
  const skeletonItems = Array.from({ length: 5 }, (_, index) => index);

  return (
    <div>
      {/* Todo items skeleton */}
      <div className="space-y-2">
        {skeletonItems.map((index) => (
          <div
            key={index}
            className="flex items-center space-x-4 pt-4 pb-4 rounded-lg bg-white"
          >
            {/* Checkbox skeleton */}
            <div className="h-7 w-7 bg-gray-200 rounded-full animate-pulse" />

            {/* Todo content skeleton */}
            <div className="flex-1 space-y-2">
              {/* Title skeleton */}
              <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
              {/* Description skeleton */}
              <div className="h-3 w-1/2 bg-gray-200 rounded animate-pulse" />
            </div>

            {/* Action button skeleton */}
            <div className="h-6 w-6 bg-gray-200 rounded animate-pulse" />
          </div>
        ))}
      </div>
    </div>
  );
};

export const ProtectedRoute = ({ children }) => {
  const { loading, user } = useFirebase();

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!user) {
    return <Navigate to="/join" />;
  }

  return children;
};

const OverflowWarpper = ({ children }) => {
  const location = useLocation();
  const isAuthedPaths = authedPaths.includes(location.pathname);

  return (
    <div
      className={`max-w-2xl mx-auto w-full p-4 flex flex-col flex-grow ${
        isAuthedPaths ? "" : "overflow-hidden"
      }`}
    >
      {children}
    </div>
  );
};

function App() {
  return (
    <FirebaseProvider>
      <CheckIfHasYesterdayTask>
        <Router>
          <CheckAuth />
          <OverflowWarpper>
            <Header />
            <Drawer />

            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route
                path="/today"
                element={
                  <ProtectedRoute>
                    <Today />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/goals"
                element={
                  <ProtectedRoute>
                    <Goals />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/daily-wins"
                element={
                  <ProtectedRoute>
                    <DailyWins />
                  </ProtectedRoute>
                }
              />
              <Route path="/join" element={<LoginPage />} />
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <AccountPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </OverflowWarpper>
        </Router>
      </CheckIfHasYesterdayTask>
    </FirebaseProvider>
  );
}

export default App;

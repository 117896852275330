import React from "react";
import { Sparkles, Star, Target } from "lucide-react";

const EmptyGoalsScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 mt-8">
      <div className="text-center max-w-md">
        {/* Enhanced illustration */}
        <div className="mb-8 relative w-48 h-32 mx-auto">
          {/* Main circle with target */}
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-20 h-20 relative">
              <div className="absolute inset-0 bg-rose-50 rounded-full flex items-center justify-center">
                <Target className="w-10 h-10 text-rose-400" />
              </div>
              <Sparkles className="absolute -right-2 -top-2 w-6 h-6 text-yellow-400" />
            </div>
          </div>

          {/* Left side decorative elements */}
          <div className="absolute left-4 top-1/3">
            <div className="space-y-2">
              <div className="w-2 h-2 bg-blue-100 rounded-full" />
              <div className="w-3 h-3 bg-green-100 rounded-full" />
              <div className="w-2 h-2 bg-rose-100 rounded-full" />
            </div>
          </div>

          {/* Right side decorative elements */}
          <div className="absolute right-4 top-1/3">
            <div className="space-y-2">
              <div className="w-3 h-3 bg-yellow-100 rounded-full" />
              <div className="w-2 h-2 bg-purple-100 rounded-full" />
              <Star className="w-4 h-4 text-blue-200" />
            </div>
          </div>

          {/* Extra floating elements */}
          <div className="absolute left-10 top-2">
            <div className="w-2 h-2 bg-yellow-200 rounded-full" />
          </div>
          <div className="absolute right-12 bottom-0">
            <div className="w-2 h-2 bg-green-200 rounded-full" />
          </div>
        </div>

        {/* Text content */}
        <h2 className="text-2xl font-semibold text-gray-900 mb-3">
          Ready to set some goals?
        </h2>
        <p className="text-gray-600 mb-8">
          Try breaking down larger ambitions into smaller, manageable goals.
        </p>
        <p className="text-gray-600 mb-8">
          Tap the + button to add your first goal!
        </p>

        {/* Help link */}
        {/* <div className="mt-2">
          <a
            href="/test"
            className="inline-flex items-center text-rose-600 hover:text-rose-700"
          >
            <span className="underline mr-1">How to set achievable goals</span>
            <span>?</span>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default EmptyGoalsScreen;

import React, { useEffect, useRef, useState } from "react";
import { Cropper } from "react-cropper";
import { Loader2, Pencil, Trash2, X } from "lucide-react";
import * as Dialog from "@radix-ui/react-dialog";
import "cropperjs/dist/cropper.css";
import { useFirebase } from "../firebase/context";
import { formatDateToDayMonthYear } from "../utils";

const ImageCropModal = () => {
  const [isViewMode, setIsViewMode] = useState(false);
  const [descValidationError, setDescValidationError] = useState("");
  const {
    selectedWin,
    setSelectedWin,
    uploadAndSaveWinToFirebase,
    uploadedImage,
    setUploadedImage,
    croppedImage,
    setCroppedImage,
    isCropping,
    setIsCropping,
    setCroppedFile,
    isWinModalOpen,
    toggleWinModal,
    isLoadingUpload,
    removeWinInFirebase,
  } = useFirebase();

  const cropperRef = useRef(null);

  useEffect(() => {
    if (selectedWin.id) {
      setIsViewMode(true);
    }
    if (selectedWin.id && selectedWin.url) {
      setCroppedImage(selectedWin.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWin.id, selectedWin.url]);

  const loadImageInMemory = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
        setIsCropping(true);
        setCroppedImage(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = async () => {
    if (!uploadedImage || !cropperRef.current) return;

    const cropper = cropperRef.current?.cropper;
    const croppedCanvas = cropper.getCroppedCanvas({
      width: 800,
      height: 800,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: "high",
    });

    // Convert canvas to blob
    const blob = await new Promise((resolve) => {
      croppedCanvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        0.9
      );
    });

    // Create a File object from the blob
    const tempNameId = new Date().getTime().toString();
    const file = new File([blob], `win-${tempNameId}.jpg`, {
      type: "image/jpeg",
    });

    // Store the cropped image URL for preview and file
    setCroppedFile(file); // Store the File object
    setCroppedImage(croppedCanvas.toDataURL("image/jpeg"));
    setIsCropping(false);
  };

  const handleSave = async () => {
    const errors = [];
    if (selectedWin.description && selectedWin.description.length > 400) {
      setDescValidationError("Description is too long");
      errors.push({ error: "description" });
    }

    if (errors.length) {
      return;
    }

    if (!croppedImage && !selectedWin.description) return;
    await uploadAndSaveWinToFirebase();
  };

  const handleCancel = () => {
    if (isCropping) {
      setUploadedImage(null);
      setIsCropping(false);
    } else {
      toggleWinModal(!isWinModalOpen);
      setSelectedWin({});
      setCroppedImage(null);
      setUploadedImage(null);
      setIsCropping(false);
      setCroppedFile(null);
    }
    setDescValidationError("");
    setIsViewMode(false);
  };

  const getModalTitle = () => {
    if (isViewMode) {
      return `Win 🎉 ${formatDateToDayMonthYear(selectedWin.date)}`;
    }
    if (isCropping) {
      return "Crop Image";
    }
    if (selectedWin.id) {
      return "Update Win 🎉";
    }
    return "Record New Win 🎉";
  };

  return (
    <Dialog.Root
      onOpenChange={() => {
        if (isLoadingUpload) {
          return;
        }
        toggleWinModal(!isWinModalOpen);
        setUploadedImage(null);
        setIsCropping(false);
        setSelectedWin({});
        setCroppedImage(null);
        setCroppedFile(null);
        setIsViewMode(false);
      }}
      open={isWinModalOpen}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40 z-50" />
        <Dialog.Content className="fixed left-[50%] top-[40%] translate-x-[-50%] translate-y-[-40%] bg-white rounded-lg p-4 sm:p-6 max-w-3xl w-[calc(100%-2rem)] max-w-xl my-4 max-h-[90vh] focus:outline-none z-50 flex flex-col">
          <div className="flex justify-between items-center mb-4">
            <Dialog.Title className="text-xl font-semibold">
              {getModalTitle()}
            </Dialog.Title>
            {isViewMode && (
              <button
                className="cursor-pointer"
                disabled={isLoadingUpload}
                onClick={handleCancel}
              >
                <X />
              </button>
            )}
          </div>

          <div className="space-y-4 flex-1 overflow-y-auto">
            {isViewMode && (
              <div className="space-y-4">
                {croppedImage && (
                  <div className="flex justify-center">
                    <img
                      src={croppedImage}
                      alt="Achievement"
                      className="w-[100%] max-w-[20rem] object-cover rounded-lg"
                    />
                  </div>
                )}
                <div className="space-y-2">
                  <h3 className="font-medium text-gray-900">Description</h3>
                  <p className="text-gray-700">{selectedWin.description}</p>
                </div>
                <div className="flex justify-end justify-between gap-2 mt-4">
                  <button
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center gap-2"
                    onClick={() => {
                      setIsViewMode(false);
                    }}
                  >
                    <Pencil className="w-4 h-4" />
                    Edit
                  </button>
                  <button
                    className="text-red-600 hover:text-red-700 transition-colors border border-red-600 rounded-lg p-2 flex items-center gap-2"
                    onClick={() => {
                      if (selectedWin && selectedWin.id) {
                        removeWinInFirebase(selectedWin.id);
                      }
                    }}
                  >
                    <Trash2 className="w-5 h-5" />
                    Delete
                  </button>
                </div>
              </div>
            )}

            {!uploadedImage && !croppedImage && !isViewMode && (
              <>
                <div className="flex justify-center mb-6">
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={loadImageInMemory}
                    />
                    <div className="px-4 mt-2 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors">
                      Choose Image
                    </div>
                  </label>
                </div>

                <div className="space-y-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    rows={3}
                    className={`w-full px-3 py-2 border rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                      descValidationError.length
                        ? "border-red-300 bg-red-100 focus:border-red-400 rounded-md pl-1"
                        : ""
                    }`}
                    placeholder="Add a description for your achievement"
                    value={selectedWin.description}
                    onChange={(e) => {
                      setSelectedWin({
                        ...selectedWin,
                        description: e.target.value,
                      });
                      if (descValidationError.length && e.target.value.length) {
                        setDescValidationError("");
                      }
                    }}
                  />
                  {Boolean(descValidationError.length) && (
                    <div className="text-red-500">{descValidationError}</div>
                  )}
                </div>
              </>
            )}

            {uploadedImage && isCropping && !isViewMode && (
              <div className="flex-1 min-h-0 h-96 rounded-lg bg-gray-100">
                <Cropper
                  ref={cropperRef}
                  src={uploadedImage}
                  style={{ height: "100%", width: "100%" }}
                  aspectRatio={1}
                  guides={true}
                  viewMode={1}
                  dragMode="move"
                  background={false}
                  cropBoxMovable={true}
                  cropBoxResizable={true}
                  autoCropArea={1}
                  responsive={true}
                  wheelZoomRatio={0.1}
                  touchDragZoom={true}
                  minContainerWidth={200}
                  minContainerHeight={200}
                  minCanvasWidth={100}
                  minCanvasHeight={100}
                  minCropBoxWidth={50}
                  minCropBoxHeight={50}
                />
              </div>
            )}

            {croppedImage && !isCropping && !isViewMode && (
              <>
                <div className="flex justify-center">
                  <div className="relative">
                    <button
                      onClick={() => {
                        setUploadedImage(null);
                        setCroppedImage(null);
                        setCroppedFile(null);
                      }}
                      disabled={isLoadingUpload}
                      className={`absolute top-1 right-1 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100 border border-gray-200 ${
                        isLoadingUpload ? "cursor-not-allowed" : ""
                      }`}
                    >
                      <X className="w-5 h-5 text-gray-700" />
                    </button>
                    <img
                      src={croppedImage}
                      alt="Cropped preview"
                      className="w-64 h-64 object-cover rounded-lg"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    rows={3}
                    className="w-full px-3 py-2 border rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Add a description for your achievement..."
                    value={selectedWin.description}
                    onChange={(e) =>
                      setSelectedWin({
                        ...selectedWin,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            )}

            {!isViewMode && (
              <div className="flex justify-end gap-2 mt-4">
                {isCropping ? (
                  <>
                    <button
                      className="px-4 py-2 border rounded-md hover:bg-gray-50"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                      onClick={handleCropComplete}
                    >
                      Save Crop
                    </button>
                  </>
                ) : (
                  <>
                    <div>
                      <button
                        className={`px-4 py-2 border rounded-md hover:bg-gray-50 ${
                          isLoadingUpload ? "cursor-not-allowed" : ""
                        }`}
                        onClick={handleCancel}
                        disabled={isLoadingUpload}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        className={`px-4 py-2 rounded-md text-white flex gap-2 ${
                          (!croppedImage && !selectedWin.description) ||
                          isLoadingUpload
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-blue-600 hover:bg-blue-700"
                        }`}
                        onClick={handleSave}
                        disabled={
                          (!croppedImage && !selectedWin.description) ||
                          isLoadingUpload
                        }
                      >
                        Save{" "}
                        {isLoadingUpload && (
                          <Loader2 className="animate-spin" />
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ImageCropModal;

import FloatingActionButton from "./floating-button";
import GoalDrawer from "./goal-drawer";
import TaskDrawer from "./task-drawer";
import { useFirebase } from "../firebase/context";
import { useLocation } from "react-router-dom";

const Drawer = () => {
  const { user, drawerOpen, isFutureTasksModal } = useFirebase();
  const location = useLocation();

  if (!user) return;

  if (!drawerOpen) {
    return <FloatingActionButton />;
  }

  if (location.pathname === "/goals" && !isFutureTasksModal) {
    return <GoalDrawer />;
  }

  if (
    location.pathname === "/today" ||
    (location.pathname === "/goals" && isFutureTasksModal)
  ) {
    return <TaskDrawer />;
  }
};

export default Drawer;

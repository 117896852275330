import { UTILITIES_GOAL } from "./constants";

const getStartOfToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

export const isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const getTomorrow = () => {
  const tomorrow = new Date(getStartOfToday());
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const getDisplayDate = (dateParam) => {
  const date = new Date(dateParam);
  const diffTime = date.getTime() - getStartOfToday().getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (isSameDate(date, getStartOfToday())) return "Today";
  if (isSameDate(date, getTomorrow())) return "Tomorrow";

  if (diffDays > 0 && diffDays < 8) {
    return date.toLocaleString("default", { weekday: "long" });
  }

  return `${date.getDate()} ${date.toLocaleString("default", {
    month: "short",
  })}`;
};

export function getDate(date = new Date()) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function sortByDueOnDay(a, b) {
  const dateA = new Date(a.dueOnDay);
  const dateB = new Date(b.dueOnDay);
  return dateA - dateB;
}

export function categorizeByUniqueDates(tasks) {
  const categorized = [];

  tasks.forEach((task) => {
    const dateEntry = categorized.find((entry) => entry.date === task.dueOnDay);
    if (dateEntry) {
      dateEntry.tasks.push(task);
    } else {
      categorized.push({ date: task.dueOnDay, tasks: [task] });
    }
  });

  return categorized;
}

export function formatDateToDayMonth(date) {
  // Map of month numbers to their abbreviated names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Split the date into components
  const [year, month, day] = date.split("-");

  // Convert month number to abbreviated name
  const monthName = months[parseInt(month, 10) - 1];

  // Return the old format
  return `${parseInt(day, 10)} ${monthName}`;
}

export function formatDateToDayMonthYear(date) {
  if (!date) return "";
  // Map of month numbers to their abbreviated names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Split the date into components
  const [year, month, day] = date.split("-");

  // Convert month number to abbreviated name
  const monthName = months[parseInt(month, 10) - 1];

  // Return the old format
  return `${parseInt(day, 10)} ${monthName} ${year}`;
}

export function countFutureTasksByGoal(goals, tasks) {
  const futureTasksByGoal = {};

  // Initialize future task counts for each goal
  [...goals, UTILITIES_GOAL].forEach((goal) => {
    futureTasksByGoal[goal.id] = 0;
  });

  // Get today's date
  const today = new Date().toISOString().split("T")[0];

  // Count future tasks per goal
  tasks.forEach((task) => {
    if (task.dueOnDay > today && task.goalId in futureTasksByGoal) {
      futureTasksByGoal[task.goalId]++;
    }
  });

  return futureTasksByGoal;
}

export function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}

export function setCookie(name, value, days = 30) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Convert days to milliseconds
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/`;
}

export function doesCookieExist(cookieName) {
  // Get all cookies as a string
  const cookies = document.cookie;

  // Check if the cookie exists in the cookies string
  return cookies
    .split("; ")
    .some((cookie) => cookie.startsWith(`${cookieName}=`));
}

import React, { useState } from "react";
import { ChevronDown } from "lucide-react";

const FAQItem = ({ question, answer, isOpen, onClick }) => (
  <div className="border-b border-gray-200">
    <button
      className="w-full py-6 text-left flex justify-between items-center focus:outline-none"
      onClick={onClick}
    >
      <span className="text-xl font-medium text-gray-900">{question}</span>
      <ChevronDown
        className={`w-5 h-5 text-gray-500 transition-transform ${
          isOpen ? "transform rotate-180" : ""
        }`}
      />
    </button>
    <div
      className={`transition-all duration-300 ease-in-out ${
        isOpen
          ? "max-h-96 opacity-100 mb-6"
          : "max-h-0 opacity-0 pointer-events-none"
      }`}
    >
      <p className="text-gray-600 text-lg">{answer}</p>
    </div>
  </div>
);

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const faqData = [
    {
      question: "How can I get access to DailyWins?",
      answer:
        "We’re currently offering limited spots to ensure the best experience for all users. Sign up now to secure your access before it’s gone. Once you’re in, you can start taking action right away!",
    },
    {
      question: "Why are there limited spots available?",
      answer:
        "We want to make sure everyone has a productive experience with DailyWins. We're launching in phases to gather feedback, make adjustments, and ensure the app helps users take meaningful action without feeling overwhelmed.",
    },
    {
      question: "Is DailyWins free?",
      answer:
        "Yes! DailyWins is currently free for members during the early access phase. We’re focused on helping you build momentum and achieve your goals. Once we launch publicly, we’ll introduce plans — but early users will receive exclusive perks.",
    },
    {
      question: "How is DailyWins different from other to-do apps?",
      answer:
        "Unlike traditional to-do apps that focus on managing endless lists, DailyWins encourages you to take action every day. With features like the 'Starter', task breakdowns, and daily wins timelines, it’s all about building momentum and making real progress — one step at a time.",
    },
    {
      question: "What’s a “Daily Win”?",
      answer:
        "A Daily Win is any small step you take toward your goals. It could be finishing a task, making progress on a project, or simply taking action on something that matters to you. DailyWins encourages you to celebrate these moments by collecting these little moments on your timeline to keep your motivation high.",
    },
    {
      question: "Can I upload Daily Wins every day?",
      answer:
        "Absolutely! Logging your wins daily is key to building momentum. You’ll see your progress timeline grow, which helps you stay motivated and focused on what truly matters.",
    },
    {
      question: "How does DailyWins help me avoid overwhelm?",
      answer:
        "DailyWins prioritizes your most important goals and keeps everyday utilities and routine tasks hidden in the background. It encourages you to break tasks into small, actionable steps so you can finish something meaningful every day without feeling overloaded.",
    },
  ];

  return (
    <div className="py-12">
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold mb-3">FAQ</h2>
      </div>
      <div className="space-y-1">
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={index === openIndex}
            onClick={() => setOpenIndex(index === openIndex ? -1 : index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQ;

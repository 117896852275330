import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { doesCookieExist, removeCookie, setCookie } from "../utils";
import { useFirebase } from "../firebase/context";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore/lite";
import { auth, db } from "../firebase";
import { authedPaths } from "../utils/constants";

const CheckAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    user,
    setUser,
    setLoading,
    setGoals,
    setTasks,
    setWins,
    setLoginError,
  } = useFirebase();
  const hasLoggedIn = doesCookieExist("hasLoggedIn");
  const isAuthedPath = authedPaths.includes(location.pathname);

  useEffect(() => {
    if (hasLoggedIn && location.pathname === "/" && !searchParams.has("a")) {
      navigate("/today");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && !hasLoggedIn) {
      setCookie("hasLoggedIn", "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const fetchUserData = async (user) => {
      if (!user) {
        setLoginError("Session expired. Please log in again.");
        removeCookie("hasLoggedIn");
        signOut(auth);
        navigate("/join");
        return;
      }

      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(userDocRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            setGoals(data.goals || []);
            setTasks(data.tasks || []);
            setWins(data.wins || []);
          }

          setUser(user);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }

        setLoading(false);
      }
    };

    const unsubscribe = isAuthedPath
      ? onAuthStateChanged(auth, (user) => {
          fetchUserData(user);
        })
      : () => {};

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthedPath]);
};

export default CheckAuth;

import React from "react";
import { Plus } from "lucide-react";
import { useFirebase } from "../firebase/context";
import { useLocation } from "react-router-dom";
import FloatingButtonGoalPicker from "./drawer-goal-picker";

const FloatingActionButton = () => {
  const {
    toggleDrawer,
    toggleWinModal,
    hasYesterdayTask,
    goals,
    isFutureTasksModal,
  } = useFirebase();
  const location = useLocation();
  const path = location.pathname;

  const hideButton =
    isFutureTasksModal ||
    path === "/join" ||
    path === "/" ||
    path === "/account" ||
    (hasYesterdayTask && path === "/today") ||
    (!Boolean(goals.length) && path === "/today");
  const isGoalsPage = path === "/daily-wins";

  const ctaHandler = isGoalsPage ? toggleWinModal : toggleDrawer;

  if (hideButton) return null;

  if (path === "/today") {
    return <FloatingButtonGoalPicker />;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 pointer-events-none z-50">
      <div className="mx-auto max-w-2xl p-4 flex justify-end">
        <button
          onClick={ctaHandler}
          className="pointer-events-auto w-14 h-14 bg-blue-500 hover:bg-blue-600 rounded-full flex items-center justify-center shadow-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
          aria-label="Add new goal/task/win"
        >
          <Plus className="w-8 h-8 text-white" />
        </button>
      </div>
    </div>
  );
};

export default FloatingActionButton;

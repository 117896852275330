export const UTILITIES_GOAL = {
  id: "utilities",
  title: "Utilities",
  color: "#6B7280",
};

export const taskUtilitiesGoal = {
  goalId: UTILITIES_GOAL.id,
  color: UTILITIES_GOAL.color,
};

export const authedPaths = ["/today", "/goals", "/daily-wins", "/account"];

/* eslint-disable jsx-a11y/anchor-is-valid */
import { CheckCircle, CheckCircle2 } from "lucide-react";
import React, { useState } from "react";
import Pricing from "./pricing";
import FAQ from "./faq";
import leftCornerTick from "../assets/ticks-left-corner.svg";
import rightCornerTick from "../assets/ticks-right-corner.svg";
import Sparkle1 from "../assets/sparkle1";
import Sparkle2 from "../assets/sparkle2";
import Squiggle1 from "../assets/squiggle1";
import Forbes from "../assets/forbes";
import { doesCookieExist } from "../utils";
import { Link, useSearchParams } from "react-router-dom";

const LandingPage = () => {
  const [isPaid] = useState(false);
  const hasLoggedIn = doesCookieExist("hasLoggedIn");
  const [searchParams] = useSearchParams();

  if (hasLoggedIn && !searchParams.has("a")) return null;

  return (
    <div className="min-h-screen bg-white">
      {/* Main Content */}
      <main className="pb-12">
        {/* Placeholder Image */}
        <div className="">
          <img
            src="/media/dailywins-header.png"
            alt="Time tracking interface"
            className="w-[350px] m-auto h-auto"
          />
        </div>

        {/* Text Content */}
        <div className="mt-10">
          <h1 className="text-[2.0rem] leading-[2.6rem] md:text-[2.8rem] md:leading-[3.5rem] font-serif mb-6 md:mb-8">
            "I’ve been through{" "}
            <span className="decoration-blue-200 decoration-8 underline">
              so many productivity apps
            </span>{" "}
            in the last year, I’ve lost track..."
            {/* "I’ve tried <span className="decoration-blue-200 decoration-8 underline">more productivity apps</span> than I care to admit in the last year..." */}
            {/* "I’ve been through so many productivity apps in the last year, I’ve lost track..." */}
          </h1>

          <p className="mb-8">
            Most apps are built to manage tasks and lists, not to help you take
            action on the goals that truly matter.
          </p>

          <p className="text-xl mb-8">
            Action is the <span className="italic">fuel</span> that drives your
            life — it’s what propels you to start, create, and complete the
            experiences that shape who you are.
          </p>
          <p className="text-xl mb-12">
            Make every day count by taking even the smallest action, because
            progress is built step by step:
          </p>
          <div className="space-y-6 text-left max-w-2xl mx-auto">
            <div className="flex items-start space-x-4">
              <div className="mt-[2px] flex-shrink-0 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center text-white text-sm">
                ?
              </div>
              <p className="text-xl">
                Do your daily actions align with your goals?
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div className="mt-[2px] flex-shrink-0 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center text-white text-sm">
                ?
              </div>
              <p className="text-xl">
                Which actions move you closer to your purpose, and which pull
                you off track?
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div className="mt-[2px] flex-shrink-0 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center text-white text-sm">
                ?
              </div>
              <p className="text-xl font-bold">
                Are you focused on what truly matters, or just staying busy?
              </p>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-10 font-sans text-xl leading-relaxed">
          <h1 className="text-xl font-semibold mb-6">
            To answer these questions, you need to take consistent action toward
            the life you want to create.
          </h1>

          <p className="mb-6">
            But you're overwhelmed by{" "}
            <span className="italic">managing tasks</span>, and sometimes,
            actoss multiple lists. It eats up your time and energy, burying your
            real goals under a mountain of meaningless to-dos. In fact,
            statistics show that 70% of tasks aren't essential to achieving what
            truly matters.
          </p>

          <p className="mb-6">
            Most to-do apps are built to help you manage tasks, not to take
            meaningful action. They encourage you to capture every incoming task
            so nothing gets forgotten — but all that effort often leaves you
            stuck. Instead of moving forward, you end up caught in a cycle of
            busywork, chasing tasks instead of making real progress.
          </p>

          <p className="mb-6">
            What if you could break that cycle and finally focus on what moves
            you forward?
          </p>
        </div>

        <div
          className="p-8 my-8 bg-white rounded-3xl shadow-lg relative"
          style={{ boxShadow: "0 0 20px rgb(224, 224, 224)" }}
        >
          {/* Decorative corners */}
          <div className="absolute top-4 left-4 rotate-90 text-rose-500">
            <Sparkle1 />
          </div>
          <div className="absolute top-4 right-4 text-rose-500">
            <Sparkle2 />
          </div>

          {/* Quote content */}
          <div className="mt-6 mb-8">
            <h2 className="text-3xl font-bold mb-6 text-center">
              "...<span className="text-blue-500">DailyWins</span> is by far the{" "}
              <span className="underline decoration-4 decoration-blue-300">
                easiest tool
              </span>{" "}
              I've ever come across for making real progress"
            </h2>

            <p className="text-gray-700 text-xl leading-relaxed text-justify">
              "I’ve switched between so many to-do apps in the past year, and
              DailyWins is by far the easiest tool I've ever come across for
              making real progress. The simple addition of a 'starter' to my
              tasks makes me focus on beginning work immediately. Seeing how
              many days I’ve postponed a task encourages me to either break it
              down into something smaller or reflect on what’s really blocking
              me. It’s unbelievable how good it feels to make steady progress!"
            </p>
          </div>

          {/* Author section */}
          <div className="flex items-center justify-center gap-4 mt-8">
            <img
              src="/media/testimonial1.jpg"
              alt="Profile"
              className="w-16 h-16 rounded-full"
            />
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <h3 className="text-xl font-bold text-purple-700">
                  Catalina Serban
                  <div className="ml-3 inline-flex items-center px-3 py-1 rounded-lg bg-blue-50 text-blue-400 text-sm font-medium">
                    #Software Engineer
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </div>

        {/* Some more text */}
        <div className="py-6 space-y-8">
          <p className="text-xl text-gray-700">
            How would your life look if you started taking action today? Where
            could you be in just one year?
          </p>

          <div className="space-y-4">
            <h2 className="text-xl text-gray-700">Imagine:</h2>
            {[
              "No more overwhelm from meaningless tasks.",
              "You take consistent action, at your own pace.",
              "Your life starts moving in the direction you truly want.",
              "You feel empowered, in control.... and!!",
              "Finally focused on what really matters!",
            ].map((benefit, index) => (
              <div
                key={index}
                className="flex items-start space-x-3 transition-all duration-200"
              >
                <CheckCircle2
                  className={`flex-shrink-0 w-6 h-6 mt-1 text-green-500`}
                />
                <p className="text-xl text-gray-700">{benefit}</p>
              </div>
            ))}
          </div>

          <div className="space-y-4">
            {[
              "And while you're imagining that scenario... What if there were no endless task lists, no constant management? What would that mean for your life, your relationships, and the goals that truly matter to you?",
              "What if your productivity tool went the extra mile to serve you?",
            ].map((text, index) => (
              <p key={index} className="text-xl text-gray-700">
                {text}
              </p>
            ))}
          </div>
        </div>

        {/* Forbes Quote */}
        <div className="">
          <div className="bg-gray-100 px-6 py-10 sm:p-10">
            <blockquote className="mb-10">
              <p className="text-2xl text-center font-bold leading-tight">
                "If you find you're spending more time managing your to-do list
                than actually getting work done, it's time to refocus on what
                truly matters."
              </p>
            </blockquote>
            <div className="flex items-center justify-center">
              <Forbes />
            </div>
          </div>
        </div>

        {/* Benefits and other */}
        <div className="py-8 space-y-6 font-sans max-w-3xl mx-auto">
          <p className="text-xl">
            I struggled with procrastination and hated to-do tools that were all
            about managing tasks (methodology "GTD") instead of having the focus
            on making progress.
          </p>

          <div className="flex flex-col md:flex-row md:items-start md:gap-8 space-y-4 md:space-y-0">
            <div className="space-y-4">
              <p className="text-lg font-semibold mb-8 mt-4">
                That's why I designed{" "}
                <span className="text-blue-500">DailyWins</span>
              </p>

              <p className="text-xl leading-relaxed">
                Designed from the ground up to help you take meaningful action
                every day... with less stress, no distractions, and zero wasted
                time.
              </p>
            </div>

            <div className="w-full md:w-80 md:flex-shrink-0">
              <div
                className="py-8 px-6 mt-4 my-8 bg-white rounded-3xl shadow-lg relative"
                style={{ boxShadow: "0 0 20px rgb(224, 224, 224)" }}
              >
                {/* Decorative corners */}
                <div className="absolute bottom-4 left-2 rotate-90 text-rose-500">
                  <Sparkle1 />
                </div>
                <div className="absolute -top-4 -right-6 text-rose-500">
                  <Squiggle1 />
                </div>

                {/* Quote content */}
                <div className="mb-8">
                  <p className="text-gray-700 text-xl font-bold leading-relaxed text-center">
                    "DailyWins has put me on the right path."
                  </p>
                </div>

                {/* Author section */}
                <div className="flex items-center justify-center gap-4 mt-8">
                  <img
                    src="/media/testimonial1.jpg"
                    alt="Profile"
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="flex flex-col">
                    <div className="flex items-center gap-2">
                      <h3 className="text-xl font-bold text-purple-700">
                        <div>Catalina Serban</div>
                        <div className="inline-flex items-center px-3 py-1 rounded-lg bg-blue-50 text-blue-400 text-sm font-medium">
                          #Software Engineer
                        </div>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="text-xl leading-relaxed">
            When you use DailyWins, you're using a tool designed to help you
            make real progress, to understand that...
          </p>

          <div className="space-y-4 py-4">
            {[
              "Taking action on goals that truly matter",
              "Breaking tasks down to make starting easy",
              "Focusing on taking the first step, not overthinking",
              "Staying present and prioritizing just for Today",
            ].map((text, index) => (
              <div key={index} className="flex items-center gap-3">
                <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0" />
                <span className="text-xl">{text}</span>
              </div>
            ))}
          </div>

          <p className="text-xl text-center">
            ...In addition, you can add a 'starter' to make taking action
            effortless 🚀
          </p>

          <div className="w-full overflow-hidden rounded-lg pb-10">
            <img
              src="/media/demo1.gif"
              alt="Time entry demonstration"
              className="max-w-[500px] border border-blue-200 rounded-lg m-auto w-full h-auto"
            />
          </div>

          <p className="text-xl text-center">
            ...Then, add 'daily wins' to celebrate 🎉 and keep the momentum
            going!
          </p>

          <div className="w-full overflow-hidden rounded-lg">
            <img
              src="/media/demo2.gif"
              alt="Time entry demonstration"
              className="max-w-[500px] border border-blue-200 rounded-lg m-auto w-full h-auto"
            />
          </div>
        </div>

        {/* How DailyGoals help you? */}
        <div className="">
          <h1 className="text-3xl font-bold mb-8">
            How will{" "}
            <span className="underline decoration-4 decoration-blue-300">
              DailyWins
            </span>{" "}
            help you?
          </h1>

          <p className="text-xl mb-8">
            You'll be pleased to know that DailyWins does give you all the basic
            features you've come to expect from a task management tool:
          </p>

          <div className="space-y-4 mb-8">
            {[
              "Effortless Task Creation",
              "Quick & Easy Task Editing",
              "Mark as Done – Feel That Sweet Satisfaction",
              "Crystal-Clear Task Overview",
            ].map((feature, index) => (
              <div key={index} className="flex items-center gap-2">
                <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0" />
                <span className="text-xl">{feature}</span>
              </div>
            ))}
          </div>

          <p className="text-xl mb-8">
            But that's not all. You need more than just a task logger or goal
            management — or you wouldn't be here. Here's what you get when you
            use DailyWins (and not those other, basic tools):
          </p>

          <div className="space-y-4">
            <div className="flex space-x-3">
              <CheckCircle className="mt-[3px] w-6 h-6 text-green-500" />
              <span className="text-xl flex-1">
                The Clean-Up 🧹✨ of unimportant tasks is built in! Unlike other
                apps, tasks won’t pile up. You’ll{" "}
                <b>focus on what truly matters</b>, and keep moving forward.
              </span>
            </div>

            <div className="flex space-x-3">
              <CheckCircle className="mt-[3px] w-6 h-6 text-green-500" />
              <span className="text-xl flex-1">
                Focus on <b>today’s 'win'</b> 🏆 — DailyWins encourages you to
                be present and take action on what you can do today. It’s
                designed to make progress simple, helping you move forward one
                step at a time, without overthinking or overwhelm.
              </span>
            </div>

            <div className="flex space-x-3">
              <CheckCircle className="mt-[3px] w-6 h-6 text-green-500" />
              <span className="text-xl flex-1">
                Logging your wins daily <b>builds momentum</b> 🏋️‍♂️ from your
                personal progress. Each action adds up, creating a powerful
                habit of moving forward and staying focused on what truly
                matters. Instead of comparing yourself to others, you’ll see
                your own timeline of progress — a reminder of how far you’ve
                come. It builds <b>healthy motivation</b> by focusing on your
                journey, one win at a time.
              </span>
            </div>

            <div className="flex space-x-3">
              <CheckCircle className="mt-[3px] w-6 h-6 text-green-500" />
              <span className="text-xl flex-1">
                <b>Your goals</b> take the spotlight 🌟 — always front and
                center, prioritized to keep you focused on what matters most.
                The everyday tasks and utilities? They’re there too, but quietly
                organized at the bottom, out of sight, so you can keep your
                energy on <b>what truly moves you forward</b>.
              </span>
            </div>

            <div className="flex space-x-3">
              <CheckCircle className="mt-[3px] w-6 h-6 text-green-500" />
              <span className="text-xl flex-1">
                You’re encouraged to <b>break tasks down</b> 🧩 into the
                smallest steps you can <b>achieve today</b>. Progress is about
                making meaningful steps each day that add up to big results.
                <br />
                For example, need to update your CV? Try writing just 2
                paragraphs a day. In 2 weeks, you’ll be amazed at the progress!
                Small, steady actions create big results.
              </span>
            </div>
          </div>
        </div>

        {/* Start free trial */}
        <div className="flex items-center justify-center pt-10 pb-7">
          <div className="max-w-3xl mx-auto">
            {/* Main Heading */}
            <h1 className="text-4xl font-bold mb-6">
              {isPaid ? (
                <>
                  Start your free 30-day{" "}
                  <span className="text-blue-500">DailyWins</span> trial now and
                  never miss another billable hour!
                </>
              ) : (
                <>Ready to take action?</>
              )}
            </h1>

            {/* Subtext */}
            {isPaid ? (
              <div className="space-y-1 text-sm">
                <p>
                  Try DailyWins on us with our 30-day fully functional free
                  trial.
                </p>
                <p>Don't worry, you can cancel at any time.</p>
              </div>
            ) : (
              <>
                <p className="text-xl">
                  Sign up now and{" "}
                  <b>
                    <u>secure your spot!</u>
                  </b>
                </p>
                <br />
                <p className="text-xl">
                  We're launching gradually to ensure everyone stays productive,
                  making adjustments along the way to support your progress and
                  goals.
                </p>
              </>
            )}
          </div>
        </div>

        {/* Pricing Table */}
        <Pricing />

        {/* FAQ */}
        <FAQ />

        <div className="flex items-center justify-center py-5">
          <style>
            {`
              .right-tick, .left-tick {
                display: none;
              }
              .tick-container:hover .right-tick, .tick-container:hover .left-tick, .tick-container:active .right-tick, .tick-container:active .left-tick {
                display: block;
              }`}
          </style>
          <Link
            to="https://docs.google.com/forms/d/e/1FAIpQLSf9DcbSeFt248XJNfaO08Vy8WLlTGwF-VWe7w7kxn7z5fKYvg/viewform?usp=dialog"
            target="_blank"
            className="tick-container py-2 px-16 rounded-full text-xl font-semibold transition-all bg-blue-500 text-white hover:bg-white hover:text-blue-600 border border-blue-500"
            style={{
              position: "relative",
            }}
          >
            {isPaid
              ? "Start your free DailyWins trial today!"
              : "Join DailyWins and Start Taking Action!"}
            <span
              className="left-tick"
              style={{
                backgroundImage: `url(${leftCornerTick})`,
                position: "absolute",
                left: "-32px",
                bottom: "-22px",
                width: "43px",
                height: "53px",
              }}
            />
            <span
              className="right-tick"
              style={{
                backgroundImage: `url(${rightCornerTick})`,
                position: "absolute",
                right: "-32px",
                top: "-22px",
                width: "43px",
                height: "53px",
              }}
            />
          </Link>
        </div>

        {/* Footer */}
        <div className="pt-12">
          {/* All navigation items in one place */}
          <div className="flex flex-wrap gap-x-4 text-sm text-gray-600">
            {[
              "Blog",
              "Help & Support",
              "Security",
              "Privacy",
              "Terms",
              "GDPR",
            ].map((item, index) => (
              <a
                key={index}
                href="#"
                className="hover:text-gray-900 whitespace-nowrap"
              >
                {item}
              </a>
            ))}
          </div>

          <div className="text-xs text-gray-600 mt-5">
            <p>
              Turn big goals into small wins. Break it down, take action, and
              make progress every day. Win your day with DailyWins!
            </p>
            <p>© DailyWins 2025</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;

import { doc, getDoc, setDoc } from "firebase/firestore/lite";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { auth, db } from "./";
import {
  getAdditionalUserInfo,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithPopup,
  updatePassword,
} from "firebase/auth";

const initializeUserDoc = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const userDocRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(userDocRef);

  if (!docSnap.exists()) {
    await setDoc(userDocRef, {
      tasks: [],
      goals: [],
      wins: [],
      createdAt: new Date().toISOString(),
    });
  }
};

export const doCreateUserWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const response = await signInWithPopup(auth, provider);
    const user = response.user;
    const additionalUserInfo = getAdditionalUserInfo(response);

    if (user && additionalUserInfo.isNewUser) {
      await initializeUserDoc();
    }

    return response;
  } catch (error) {
    console.error("Google sign-in error:", error);
    throw error;
  }
};

export const signOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export async function uploadImage(file, winId) {
  try {
    const storage = getStorage(); // Get storage instance
    const storageRef = ref(storage, `images/win-${winId}.jpg`); // Create a reference

    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);
    console.log("Uploaded a file!");

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL; // Return the URL
  } catch (error) {
    console.error("Upload failed:", error);
  }
}

export async function deleteImage(fileName) {
  try {
    const storage = getStorage(); // Get storage instance
    const fileRef = ref(storage, `images/${fileName}`); // Create a reference to the file

    // Delete the file
    await deleteObject(fileRef);
    console.log("File deleted successfully!");

    return true; // Return success
  } catch (error) {
    console.error("File deletion failed:", error);
    return false; // Return failure
  }
}

import React, { useState } from "react";
import { Check } from "lucide-react";
import { Link } from "react-router-dom";

const Pricing = () => (
  <>
    <div className="absolute -top-8 -left-8 w-20 h-20 rotate-12">
      <svg
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-[spin_4s_linear_infinite]"
      >
        <circle cx="20" cy="20" r="4" fill="#FFD700" className="animate-ping" />
        <path
          d="M32 6L35 9L38 6L35 3L32 6Z"
          fill="#FF6B6B"
          className="animate-bounce"
        />
        <path
          d="M8 32L11 35L14 32L11 29L8 32Z"
          fill="#4FD1C5"
          className="animate-pulse"
        />
      </svg>
    </div>
    <div className="absolute -bottom-8 -right-8 w-20 h-20 -rotate-12">
      <svg
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-[spin_4s_linear_infinite]"
      >
        <circle cx="20" cy="20" r="4" fill="#7F9CF5" className="animate-ping" />
        <path
          d="M32 32L35 35L38 32L35 29L32 32Z"
          fill="#FFD700"
          className="animate-bounce"
        />
        <path
          d="M8 8L11 11L14 8L11 5L8 8Z"
          fill="#FF6B6B"
          className="animate-pulse"
        />
      </svg>
    </div>
    <div className="absolute -top-8 -right-8 w-20 h-20 rotate-45">
      <svg
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-[spin_4s_linear_infinite]"
      >
        <path
          d="M18 15L22 19L26 15L22 11L18 15Z"
          fill="#68D391"
          className="animate-ping"
        />
        <circle cx="32" cy="8" r="4" fill="#7F9CF5" className="animate-pulse" />
        <circle cx="8" cy="32" r="4" fill="#FFD700" className="animate-pulse" />
      </svg>
    </div>
    <div className="absolute -bottom-8 -left-8 w-20 h-20 -rotate-45">
      <svg
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-[spin_4s_linear_infinite]"
      >
        <path
          d="M18 15L22 19L26 15L22 11L18 15Z"
          fill="#7F9CF5"
          className="animate-ping"
        />
        <circle
          cx="32"
          cy="32"
          r="4"
          fill="#68D391"
          className="animate-pulse"
        />
        <circle cx="8" cy="8" r="4" fill="#FF6B6B" className="animate-pulse" />
      </svg>
    </div>

    <div className="absolute top-1/4 -left-6 w-4 h-4 animate-bounce">
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="3" fill="#FFD700" />
      </svg>
    </div>
    <div className="absolute top-1/4 -right-6 w-4 h-4 animate-bounce delay-300">
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="3" fill="#FF6B6B" />
      </svg>
    </div>
    <div className="absolute bottom-1/4 -left-6 w-4 h-4 animate-bounce delay-500">
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="4"
          y="4"
          width="8"
          height="8"
          fill="#7F9CF5"
          transform="rotate(45 8 8)"
        />
      </svg>
    </div>
    <div className="absolute bottom-1/4 -right-6 w-4 h-4 animate-bounce delay-700">
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="4"
          y="4"
          width="8"
          height="8"
          fill="#68D391"
          transform="rotate(45 8 8)"
        />
      </svg>
    </div>
  </>
);

const PricingCard = ({ features }) => {
  const [billingPeriod, setBillingPeriod] = useState("yearly");
  const [isPaid] = useState(false);

  return (
    <div className="p-8 rounded-3xl bg-blue-950 text-white shadow-xl relative h-full w-full max-w-md">
      <Pricing />

      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold mb-2">Action Taker</h2>
        {isPaid ? (
          <p className="text-lg mb-2">30-Day Free Trial</p>
        ) : (
          <>
            <p className="text-sm mb-2 mt-4">
              Signup for Free Today -{" "}
              <span className="font-bold">
                <u>Limited Spots Available!</u>
              </span>
            </p>
          </>
        )}
        {isPaid && (
          <div className="mt-6 bg-blue-900/50 rounded-lg p-2 inline-block">
            <div className="flex justify-center space-x-2 text-sm">
              <button
                className={`px-4 py-2 rounded-md transition-all ${
                  billingPeriod === "monthly"
                    ? "bg-blue-600 text-white"
                    : "text-blue-200 hover:text-white"
                }`}
                onClick={() => setBillingPeriod("monthly")}
              >
                Monthly
              </button>
              <button
                className={`px-4 py-2 rounded-md transition-all ${
                  billingPeriod === "yearly"
                    ? "bg-blue-600 text-white"
                    : "text-blue-200 hover:text-white"
                }`}
                onClick={() => setBillingPeriod("yearly")}
              >
                Yearly
                <span className="ml-1 text-xs text-yellow-400">
                  4 months free
                </span>
              </button>
            </div>
          </div>
        )}

        {isPaid && (
          <div className="mt-4">
            <p className="text-lg opacity-90">
              Then ${billingPeriod === "yearly" ? "10/month" : "15/month"}
            </p>
            {billingPeriod === "yearly" && (
              <p className="text-sm text-blue-200 mt-1">(paid as $120/year)</p>
            )}
          </div>
        )}
      </div>

      <div className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <Feature key={index} text={feature} />
        ))}
      </div>

      <div className="text-center pb-2">
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSf9DcbSeFt248XJNfaO08Vy8WLlTGwF-VWe7w7kxn7z5fKYvg/viewform?usp=dialog"
          target="_blank"
          className="block w-full py-4 px-6 rounded-xl text-xl font-semibold transition-all bg-blue-500 hover:bg-blue-600 text-white"
        >
          {isPaid ? "Start Free Trial" : "Sign Up Now!"}
        </Link>
      </div>

      <p className="text-center mt-4 text-sm opacity-75">
        {isPaid
          ? "No credit card required"
          : "Don’t wait! Secure your access now before all spots are taken!"}
      </p>
    </div>
  );
};

const Feature = ({ text }) => (
  <div className="flex items-center gap-3">
    <Check size={20} className="text-blue-500 flex-shrink-0" />
    <span className="text-sm">{text}</span>
  </div>
);

const PricingTable = () => {
  const features = [
    "Use the Starter feature to Kickstart Tasks",
    "Upload unlimited Daily Wins to Track Progress",
    "Break Big Tasks into Small, Doable Steps",
    "Effortlessly log your blockers and reflect!",
    "Focus on meaningful Action, Not Busywork",
  ];

  return (
    <div id="join" className="flex items-center justify-center pb-8 pt-11">
      <PricingCard features={features} />
    </div>
  );
};

export default PricingTable;

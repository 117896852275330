import React, { useEffect, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  ChevronRight,
  MoreVertical,
  CheckCircle2,
  ArrowRight,
  ArrowRightCircle,
  Text,
  ChevronDown,
} from "lucide-react";
import EmptyTasksScreen from "./empty-tasks-screen";
import { useFirebase } from "../firebase/context";
import { isPastTaskUnfinished } from "../components/check-if-has-yesterday-task";
import DeleteModal from "./delete-modal";
import isToday from "date-fns/isToday";
import { UTILITIES_GOAL } from "../utils/constants";

const Check = ({ taskChecked, color, isAnimating }) => {
  // Determine whether the check should appear completed
  const completed = taskChecked;

  return (
    <div className="flex items-center justify-center">
      <div className="relative">
        <style>
          {`
            @keyframes popCheck {
              0% {
                transform: scale(1) rotate(0deg);
              }
              40% {
                transform: scale(1.3) rotate(10deg);
              }
              70% {
                transform: scale(0.9) rotate(-5deg);
              }
              85% {
                transform: scale(1.1) rotate(2deg);
              }
              100% {
                transform: scale(1) rotate(0deg);
              }
            }

            .check path {
              stroke: white !important;
            }

            .completed-check-animate {
              animation: popCheck 0.4s ease-in-out forwards;
            }

            .completed-check path {
              stroke: white !important;
            }

            .check-container {
              transform-origin: center center;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
            }

            .check-container:hover {
              transform: scale(1.1);
            }

            .icon-fill {
              transition: fill 0.3s ease-in-out;
            }
          `}
        </style>
        <div
          className={`check-container ${
            isAnimating ? "completed-check-animate" : ""
          } ${completed ? "completed-check" : ""}`}
        >
          <CheckCircle2
            fill={completed || isAnimating ? color : "white"}
            stroke={color}
            className="text-green-500 cursor-pointer icon-fill check"
            size={30}
          />
        </div>
      </div>
    </div>
  );
};

export const TaskItem = ({ task }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const {
    goals,
    checkTaskInFirebase,
    setTask,
    toggleDrawer,
    setIsDeleteModalOpen,
    setDeletionTaskId,
  } = useFirebase();

  const goal =
    task.goalId === UTILITIES_GOAL.id
      ? {
          color: UTILITIES_GOAL.color,
          id: UTILITIES_GOAL.id,
          title: UTILITIES_GOAL.title,
        }
      : goals.find((goal) => goal.id === task.goalId);

  const checkTask = () => {
    // Start animation
    setIsAnimating(true);

    // Delay the actual state update to allow animation to complete
    setTimeout(async () => {
      await checkTaskInFirebase(task.id, {
        checked: !task.checked,
        dueOnDay: task.dueOnDay,
      });
      setIsAnimating(false); // Reset animation state after completion
    }, 400); // Match this to the duration of your animation (0.5s in this case)
  };

  return (
    <div className="relative pt-3 pb-1">
      <div className="flex items-start">
        <div onClick={checkTask} className="relative">
          <Check
            taskChecked={task.checked}
            color={goal.color}
            isAnimating={isAnimating}
          />

          {/* Days Counter Badge */}
          {Boolean(task.postponedDays) && (
            <div className="absolute -top-[5px] -right-[5px] min-w-[14px] h-[14px] px-1 rounded-full bg-orange-400 text-white text-[0.5rem] flex items-center justify-center">
              {task.postponedDays}
            </div>
          )}
        </div>

        <div
          className="ml-2 flex-1 cursor-pointer"
          onClick={() => {
            setTask(task);
            toggleDrawer(true);
          }}
        >
          {task.taskStarter ? (
            <>
              <div className="ml-2 mb-1 flex">
                <span className="font-medium flex-1">{task.taskStarter}</span>
                {task.description && <Text className="w-4 h-4 ml-4 mt-[4px]" />}
              </div>
              <div className="flex">
                <ChevronRight size={24} className="text-gray-300 mr-1" />
                <span className="text-gray-400">{task.title}</span>
              </div>
            </>
          ) : (
            <div className="font-medium ml-2 mt-0.5 flex items-center">
              <span className="">{task.title}</span>
              {task.description && <Text className="w-4 h-4 ml-4 mt-[2px]" />}
            </div>
          )}
        </div>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="-mt-1 ml-3 p-2 -mr-2 cursor-pointer outline-none">
            <MoreVertical className="text-gray-400" size={20} />
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="min-w-[180px] bg-white rounded-lg shadow-lg border border-gray-100 py-1 -mt-2 mr-1 z-10"
              sideOffset={5}
              align="end"
            >
              <DropdownMenu.Item
                className="flex px-3 py-2 text-normal hover:bg-gray-100 cursor-pointer outline-none"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setDeletionTaskId(task.id);
                }}
              >
                Delete
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

const getPosponedDays = (isSelected, postponedDays, itWasInTheFuture) => {
  if (postponedDays) {
    if (isSelected && !itWasInTheFuture) {
      return postponedDays + 1;
    } else {
      return postponedDays;
    }
  }

  if (isSelected && !itWasInTheFuture) {
    return 1;
  }

  return null;
};

const CarryOverTaskItem = ({ task, selectedTasks, setSelectedTasks }) => {
  const { goals, checkTaskInFirebase } = useFirebase();

  const goal =
    task.goalId === UTILITIES_GOAL.id
      ? {
          color: UTILITIES_GOAL.color,
          id: UTILITIES_GOAL.id,
          title: UTILITIES_GOAL.title,
        }
      : goals.find((goal) => goal.id === task.goalId);

  const isSelected = selectedTasks[task.id];
  const postponedDays = getPosponedDays(
    isSelected,
    task.postponedDays,
    task.itWasInTheFuture
  );

  return (
    <div className="relative py-3">
      <div className="flex items-start">
        <div className="relative">
          <div
            className="relative w-7 h-7 flex items-center justify-center cursor-pointer"
            onClick={() =>
              setSelectedTasks({
                ...selectedTasks,
                [task.id]: !isSelected,
              })
            }
          >
            {/* Main Circle with Arrow */}
            <div
              className="w-7 h-7 rounded-full border-2 flex items-center justify-center transition-all duration-300"
              {...(isSelected
                ? {
                    style: {
                      backgroundColor: goal.color,
                      borderColor: goal.color,
                    },
                  }
                : { style: { borderColor: goal.color } })}
            >
              <ArrowRight
                className={`w-4 h-4 transition-all duration-300 ${
                  selectedTasks[task.id] ? "text-white" : "text-gray-400"
                }`}
                {...(!isSelected && {
                  style: { color: goal.color },
                })}
              />
            </div>

            {/* Days Counter Badge */}
            {postponedDays && (
              <div className="absolute -top-[5px] -right-[5px] min-w-[14px] h-[14px] px-1 rounded-full bg-orange-400 text-white text-[0.5rem] flex items-center justify-center">
                {postponedDays}
              </div>
            )}
          </div>
        </div>

        <div className="ml-2 flex-1">
          {task.taskStarter ? (
            <>
              <div className="ml-2 mb-1">
                <span className="font-medium">{task.taskStarter}</span>
              </div>
              <div className="flex items-center">
                <ChevronRight size={24} className="text-gray-300 mr-1" />
                <span className="text-gray-400">{task.title}</span>
              </div>
            </>
          ) : (
            <div className="font-medium ml-2 mt-0.5">
              <span className="">{task.title}</span>
            </div>
          )}
        </div>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger className="-mt-1 ml-3 p-2 -mr-2 cursor-pointer outline-none">
            <MoreVertical className="text-gray-400" size={20} />
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="min-w-[180px] bg-white rounded-lg shadow-lg border border-gray-100 py-1 -mt-2 mr-1 z-10"
              sideOffset={5}
              align="end"
            >
              <DropdownMenu.Item
                className="flex px-3 py-2 text-normal hover:bg-gray-100 cursor-pointer outline-none"
                onClick={() => {
                  checkTaskInFirebase(task.id, {
                    checked: !task.checked,
                    dueOnDay: task.dueOnDay,
                  });
                }}
              >
                Check
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

const calcPostponedDays = (task) => {
  if (task.itWasInTheFuture) {
    return 0;
  }
  if (task.postponedDays) {
    return task.postponedDays + 1;
  }
  return 1;
};

const BeginToday = () => {
  const { tasks, beginDayForTasksInFirebase } = useFirebase();
  const [selectedTasks, setSelectedTasks] = useState({});

  const selection = Object.entries(selectedTasks);
  const tasksToKeep = tasks
    .filter((task) => {
      const foundTask = selection.find(([key]) => task.id === key);
      if (!foundTask) return false;
      return foundTask[1];
    })
    .map((task) => {
      const postponedDays = calcPostponedDays(task);

      return {
        ...task,
        postponedDays,
      };
    });

  const handleCarryOver = () => {
    beginDayForTasksInFirebase(tasksToKeep);
  };

  const unfinishedTasks = tasks.filter((task) => isPastTaskUnfinished(task));

  return (
    <main className="flex-grow">
      <div className="border-l-4 border-amber-500 pl-4 mb-6">
        <h3 className="text-lg font-medium mb-2">Unfinished tasks</h3>
        <p className="text-gray-600 text-sm">
          Please choose which tasks you’d like to continue working on.
          Unselected tasks will be removed.
        </p>
        <p className="text-gray-600 text-sm mt-2">
          Carry over days are highlited in orange.
        </p>
      </div>

      <div className="mb-4">
        {unfinishedTasks.map((task, i) => (
          <CarryOverTaskItem
            key={i}
            task={task}
            selectedTasks={selectedTasks}
            setSelectedTasks={setSelectedTasks}
          />
        ))}
      </div>

      <button
        className="w-full flex items-center justify-center space-x-2 px-4 py-3 mb-8 text-sm text-blue-600 bg-blue-100 hover:bg-blue-200 rounded-lg transition-colors"
        onClick={handleCarryOver}
      >
        <span>
          Move {tasksToKeep.length}{" "}
          {tasksToKeep.length === 1 ? "task" : "tasks"} to Today
        </span>
        <ArrowRightCircle className="w-4 h-4" />
      </button>
    </main>
  );
};

const TodayList = () => {
  const {
    tasks,
    setDeletionTaskId,
    setIsDeleteModalOpen,
    showChecked,
    toggleShowChecked,
  } = useFirebase();

  const tasksUnfinished = tasks.filter(
    (task) => isToday(task.dueOnDay) && (showChecked ? true : !task.checked)
  );
  const tasksWithGoals = tasksUnfinished.filter(
    (task) => task.goalId !== UTILITIES_GOAL.id
  );
  const tasksWithUtils = tasksUnfinished.filter(
    (task) => task.goalId === UTILITIES_GOAL.id
  );

  return (
    <div className="mb-12">
      <div className="flex justify-end -mt-6">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <button className="text-gray-600 hover:text-gray-800 transition-colors px-2 py-1 rounded-lg hover:bg-gray-100">
              View
            </button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="min-w-[180px] bg-white rounded-lg shadow-lg border border-gray-100 py-1 mr-1 z-10"
              sideOffset={5}
              align="end"
            >
              <DropdownMenu.Item
                className="flex px-3 py-2 text-normal hover:bg-gray-100 cursor-pointer outline-none"
                onClick={() => toggleShowChecked(!showChecked)}
              >
                {showChecked ? "Hide" : "Show"} checked
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
      {tasksWithGoals.map((task, i) => (
        <TaskItem
          key={task.id}
          task={task}
          setDeletionTaskId={setDeletionTaskId}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      ))}

      {/* Utilities Section */}
      <TasksWithUtils
        tasksWithUtils={tasksWithUtils}
        setDeletionTaskId={setDeletionTaskId}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />
    </div>
  );
};

const TasksWithUtils = ({
  tasksWithUtils,
  setDeletionTaskId,
  setIsDeleteModalOpen,
}) => {
  const [isUtilitiesOpen, toggleUtilities] = useState(false);

  return (
    <>
      {Boolean(tasksWithUtils.length) && (
        <div className="mt-8">
          <button
            onClick={() => toggleUtilities(!isUtilitiesOpen)}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <ChevronDown
              className={`w-6 h-6 transition-transform ${
                isUtilitiesOpen ? "rotate-180" : ""
              }`}
            />
            <div className="flex items-center gap-2">
              <span className="font-medium">Utilities</span>
              <span className="text-md text-gray-400">
                {tasksWithUtils.length}
              </span>
            </div>
          </button>

          {isUtilitiesOpen && (
            <div className="mt-4">
              <div className="flex flex-col">
                {tasksWithUtils.map((task, i) => (
                  <TaskItem
                    key={i}
                    task={task}
                    setDeletionTaskId={setDeletionTaskId}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Today = () => {
  const { tasks, showChecked, hasYesterdayTask, toggleShowChecked } =
    useFirebase();
  const [todayIsDone, setTodayDone] = useState(false);

  useEffect(() => {
    const todayIsDone = tasks
      .filter((task) => isToday(task.dueOnDay))
      .every((task) => task.checked && !showChecked);

    setTodayDone(todayIsDone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  if (hasYesterdayTask) {
    return <BeginToday />;
  }

  const noTasksExist = tasks.length === 0;
  const isCheckedTasksHidden = !showChecked;

  if (isCheckedTasksHidden && (noTasksExist || todayIsDone)) {
    return (
      <div className="flex flex-col bg-white text-gray-800 font-sans">
        {!noTasksExist && (
          <div className="flex justify-end -mt-6">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <button className="text-gray-600 hover:text-gray-800 transition-colors px-2 py-1 rounded-lg hover:bg-gray-100">
                  View
                </button>
              </DropdownMenu.Trigger>

              <DropdownMenu.Portal>
                <DropdownMenu.Content
                  className="min-w-[180px] bg-white rounded-lg shadow-lg border border-gray-100 py-1 mr-1 z-10"
                  sideOffset={5}
                  align="end"
                >
                  <DropdownMenu.Item
                    className="flex px-3 py-2 text-normal hover:bg-gray-100 cursor-pointer outline-none"
                    onClick={() => toggleShowChecked(!showChecked)}
                  >
                    {showChecked ? "Hide" : "Show"} checked
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
        )}
        <EmptyTasksScreen />
      </div>
    );
  }

  return <TodayList />;
};

const TodayWithDeleteModal = () => {
  return (
    <div>
      <Today />
      <DeleteModal type="task" />
    </div>
  );
};

export default TodayWithDeleteModal;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ChevronDown, Trophy, ArrowLeft } from "lucide-react";
import { useFirebase } from "../firebase/context";

import leftCornerTick from "../assets/ticks-left-corner.svg";
import rightCornerTick from "../assets/ticks-right-corner.svg";
import { doesCookieExist } from "../utils";

function getMenuName(pathName) {
  const [, path] = pathName.split("/");
  if (path === "today") return "Today";
  if (path === "goals") return "Goals";
  if (path === "daily-wins") return "Daily Wins";
  return "";
}

function isStandalone() {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    (window.navigator.standalone !== undefined && window.navigator.standalone)
  );
}

const Header = () => {
  const { user, toggleDrawer, setDrawerContent } = useFirebase();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const path = getMenuName(location.pathname);

  const handleGoBack = () => {
    navigate(-1); // -1 is equivalent to going back one step in the history stack
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const clearDrawer = () => {
    toggleDrawer(false);
    setDrawerContent(null);
  };

  const resetState = () => {
    setIsOpen(!isOpen);
    clearDrawer();
  };

  const isAccountsPage = location.pathname === "/account";
  const isLoddedIn = doesCookieExist("hasLoggedIn");

  return (
    <div className="pb-9">
      {/* Header */}
      <header className="flex justify-between items-center mb-6">
        <div className="text-xl font-semibold">
          <Link
            to={isStandalone() && user ? "/today" : "/?a"}
            className="flex gap-3 items-center"
          >
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center h-8 w-8 bg-blue-500 rounded-full">
                <Trophy className="w-5 h-5 text-white" />
              </div>
            </div>
            <span className="text-blue-500">Daily Wins</span>
          </Link>
        </div>
        <div className="flex items-center space-x-2">
          {isLoddedIn && location.pathname === "/" && (
            <div className="flex items-center space-x-4 small-tablets:space-x-2">
              <Link
                to="/today"
                className="bg-blue-500 text-white px-4 py-1 rounded-full cursor-pointer"
              >
                Open app
              </Link>
            </div>
          )}
          {isLoddedIn && location.pathname !== "/" && (
            <div
              onClick={() => {
                if (isAccountsPage) {
                  handleGoBack();
                } else {
                  navigate("/account");
                }
              }}
              className="cursor-pointer flex items-center hover:underline text-blue-400 flex items-center gap-2"
            >
              {isAccountsPage && (
                <div className="flex items-center gap-2">
                  <ArrowLeft size={16} />
                  <span>Back</span>
                </div>
              )}
              <div className="w-8 h-8 flex items-center justify-center rounded-full  cursor-pointer">
                {user && user.photoURL && (
                  <img
                    alt="User"
                    src={user.photoURL}
                    className="rounded-full w-8 h-8"
                  />
                )}
              </div>
            </div>
          )}
          {!isLoddedIn && location.pathname === "/" && (
            <>
              <style>
                {`
              .signup-left-tick, .signup-right-tick {
                display: none;
              }
              .signup-container:hover .signup-left-tick,
              .signup-container:active .signup-left-tick,
              .signup-container:hover .signup-right-tick,
              .signup-container:active .signup-right-tick {
                display: block;
              }
            `}
              </style>
              <div className="flex items-center space-x-4 small-tablets:space-x-2">
                <a
                  href="#join"
                  className="signup-container text-gray-700 px-4 py-1 rounded-full border border-gray-200 hover:border-gray-400 transition-colors"
                  style={{
                    position: "relative",
                  }}
                >
                  Try it
                  <span
                    className="signup-left-tick"
                    style={{
                      backgroundImage: `url(${leftCornerTick})`,
                      position: "absolute",
                      left: "-15px",
                      bottom: "-8px",
                      width: "23px",
                      height: "23px",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <span
                    className="signup-right-tick"
                    style={{
                      backgroundImage: `url(${rightCornerTick})`,
                      position: "absolute",
                      right: "-18px",
                      top: "-9px",
                      width: "23px",
                      height: "23px",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </a>
                <Link
                  to="/join"
                  className="bg-blue-500 text-white px-4 py-1 rounded-full cursor-pointer"
                >
                  Sign in
                </Link>
              </div>
            </>
          )}
        </div>
      </header>

      {/* Today Section */}
      {path && (
        <div
          className="relative w-full flex flex-col items-center"
          ref={dropdownRef}
        >
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="font-bold text-2xl">{path}</span>
            <ChevronDown
              className={`w-6 h-6 transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </div>

          {isOpen && (
            <div className="absolute top-full mt-2 w-48 bg-white rounded-lg shadow-lg overflow-hidden z-10">
              <ul className="list-none flex flex-col w-full">
                <li>
                  <Link
                    className="block px-4 py-3 text-center hover:bg-gray-50 cursor-pointer"
                    to="/today"
                    onClick={resetState}
                  >
                    Today
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-3 text-center hover:bg-gray-50 cursor-pointer"
                    to="/goals"
                    onClick={resetState}
                  >
                    Goals
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-3 text-center hover:bg-gray-50 cursor-pointer"
                    to="/daily-wins"
                    onClick={resetState}
                  >
                    Daily Wins
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { doCreateUserWithGoogle, signOut } from "../firebase/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { setCookie } from "../utils";
import { useFirebase } from "../firebase/context";

const AuthTabs = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { loginError, user, setLoginError } = useFirebase();
  const [searchParams] = useSearchParams();
  const hasInvite = searchParams.has("invite");

  useEffect(() => {
    if (user && user.currentUser) {
      setCookie("hasLoggedIn", "true");
      navigate("/today");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoginError("");
    setSuccess("");

    try {
      const isAuth = await doCreateUserWithGoogle();
      if (isAuth) {
        setCookie("hasLoggedIn", "true");
        setSuccess("Logged in successfully!");
        navigate("/today");
      }
      setSuccess("Account created successfully!");
    } catch (err) {
      setError("There was an error. Please try again.");
      signOut();
    }
  };

  return (
    <div className="flex items-center justify-center pb-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full space-y-8">
        {hasInvite ? (
          <>
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">Welcome</h2>
              <p className="mt-2 text-sm text-gray-600">
                Continue with your Google account
              </p>
            </div>

            <button
              onClick={handleSubmit}
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-xl text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-sm transition-all duration-150 ease-in-out"
            >
              <span className="flex items-center pl-3">
                <img
                  src="https://www.svgrepo.com/show/355037/google.svg"
                  alt="Google logo"
                  className="h-5 w-5 group-hover:opacity-80"
                />
              </span>
              <span className="pl-5">Sign in with Google</span>
            </button>

            {(error || loginError) && (
              <div className="rounded-xl bg-red-50 p-4 text-center">
                <p className="text-sm text-red-600">{error || loginError}</p>
              </div>
            )}

            {success && (
              <div className="rounded-xl bg-green-50 p-4 text-center">
                <p className="text-sm text-green-600">{success}</p>
              </div>
            )}

            <p className="mt-2 text-center text-sm text-gray-600">
              By continuing, you agree to our{" "}
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Privacy Policy
              </a>
            </p>
          </>
        ) : (
          <div className="text-center">
            Please request an{" "}
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSf9DcbSeFt248XJNfaO08Vy8WLlTGwF-VWe7w7kxn7z5fKYvg/viewform?usp=dialog"
              target="_blank"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              <u>invite</u>
            </Link>{" "}
            first!
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthTabs;

import React, { useRef, useEffect, useState } from "react";
import { ChevronDown, MoreHorizontal, X, ArrowUp } from "lucide-react";
import { useFirebase } from "../firebase/context";
import { getDisplayDate } from "../utils";
import DatePickerModal from "./date-picker-modal";
import isToday from "date-fns/isToday";
import { UTILITIES_GOAL } from "../utils/constants";
import GoalMove from "./modal-move-goal";

// task
// {
//   title: "Run 5k",
//   description: "lorem ipsum"
//   taskStarter: "Put on shoes, get out the door and reach the park",
//   goalId: "456",
//later
//   id: "111111",
//   checked: true,
//   dueOnDay: String,
// }

const TaskDrawer = () => {
  const [titleValidationError, setTitleValidationError] = useState("");
  const [descValidationError, setDescValidationError] = useState("");
  const [starterTaskValidationError, setStarterTaskValidationError] =
    useState("");
  const {
    toggleDrawer,
    goals,
    task,
    setTask,
    addTaskToFirebase,
    updateTaskInFirebase,
    isDatePickerOpen,
    isGoalPickerOpen,
    toggleGoalPicker,
    selectedGoalInModal,
    toggleDatePicker,
    selectedDatePicker,
    setSelectedDatePicker,
    setTasksInTheFuture,
  } = useFirebase();

  const selectedGoal =
    task.goalId === UTILITIES_GOAL.id
      ? {
          color: UTILITIES_GOAL.color,
          id: UTILITIES_GOAL.id,
          title: UTILITIES_GOAL.title,
        }
      : goals.find((goal) => goal.id === task.goalId);

  // State for screen size responsive behavior
  const [screenSize, setScreenSize] = useState({
    // showColor: window.innerWidth > 550,
    showToday: window.innerWidth > 430,
  });

  // State for dropdown
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: false,
    right: false,
  });

  // Refs
  const moreMenuRef = useRef(null);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const descriptionRef = useRef(null);

  // Auto-resize textarea handler
  const adjustHeight = (ref) => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  // Update dropdown position based on available space
  const updateDropdownPosition = () => {
    if (!buttonRef.current || !dropdownRef.current) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const dropdownRect = dropdownRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const spaceBelow = windowHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;
    const spaceRight = windowWidth - buttonRect.right;
    const spaceLeft = buttonRect.left;

    setDropdownPosition({
      top: spaceBelow < dropdownRect.height && spaceAbove > dropdownRect.height,
      right: spaceRight < dropdownRect.width && spaceLeft > dropdownRect.width,
    });
  };

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        // showColor: window.innerWidth > 550,
        showToday: window.innerWidth > 430,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle click outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreMenuRef.current && !moreMenuRef.current.contains(event.target)) {
        setIsMoreMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle dropdown position updates
  useEffect(() => {
    if (isMoreMenuOpen) {
      updateDropdownPosition();
      const handlePositionUpdate = () => {
        requestAnimationFrame(updateDropdownPosition);
      };

      window.addEventListener("scroll", handlePositionUpdate);
      window.addEventListener("resize", handlePositionUpdate);

      return () => {
        window.removeEventListener("scroll", handlePositionUpdate);
        window.removeEventListener("resize", handlePositionUpdate);
      };
    }
  }, [isMoreMenuOpen]);

  // Initial textarea height adjustment
  useEffect(() => {
    adjustHeight(descriptionRef);
  }, []);

  useEffect(() => {
    if (task && task.id && task.dueOnDay) {
      setSelectedDatePicker(new Date(task.dueOnDay));
    } else {
      setSelectedDatePicker(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.id]);

  const clearDrawer = () => {
    setTask(null);
    toggleDrawer(false);
    setTasksInTheFuture(null);
  };

  const handleSubmit = () => {
    if (!task.title) {
      setTitleValidationError("Title cannot be empty");
      return;
    }

    const errors = [];

    if (task.title && task.title.length > 200) {
      setTitleValidationError("Title is too long");
      errors.push({ error: "title" });
    }

    if (task.taskStarter && task.taskStarter.length > 200) {
      setStarterTaskValidationError("Task starter is too long");
      errors.push({ error: "taskStarter" });
    }

    if (task.description && task.description.length > 400) {
      setDescValidationError("Description is too long");
      errors.push({ error: "description" });
    }

    if (errors.length) {
      return;
    }

    if (task && task.id) {
      updateTaskInFirebase(task.id, task);
      clearDrawer();
      return;
    }

    addTaskToFirebase();

    clearDrawer();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 z-10">
      <div className="fixed bottom-0 left-0 right-0 w-full max-w-2xl mx-auto bg-white rounded-t-2xl shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] z-10">
        <div className="p-4">
          <div>
            <input
              autoFocus
              value={task.title}
              onChange={(e) => {
                setTask({ ...task, title: e.target.value });
                if (titleValidationError.length && e.target.value.length) {
                  setTitleValidationError("");
                }
              }}
              type="text"
              placeholder="Task name"
              className={`w-full mb-2 text-lg font-medium outline-none ${
                titleValidationError.length
                  ? "border-red-300 bg-red-100 focus:border-red-400 rounded-md pl-1"
                  : ""
              }`}
            />
            <textarea
              ref={descriptionRef}
              placeholder="Description"
              className={`w-full max-h-[110px] min-h-[40px] resize-none outline-none text-gray-600 mb-2 ${
                descValidationError.length
                  ? "border-red-300 bg-red-100 focus:border-red-400 rounded-md pl-1"
                  : ""
              }`}
              value={task.description}
              onChange={(e) => {
                adjustHeight(descriptionRef);
                setTask({ ...task, description: e.target.value });
                if (descValidationError.length && e.target.value.length) {
                  setDescValidationError("");
                }
              }}
              rows={1}
            />
            <div className="relative">
              <input
                placeholder="The tiniest first step"
                className={`w-full min-h-[40px] resize-none outline-none text-gray-600 bg-gray-50 rounded-md p-3 ${
                  starterTaskValidationError.length
                    ? "border-red-300 bg-red-100 focus:border-red-400 rounded-md pl-1"
                    : ""
                }`}
                value={task.taskStarter}
                onChange={(e) => {
                  setTask({ ...task, taskStarter: e.target.value });
                  if (
                    starterTaskValidationError.length &&
                    e.target.value.length
                  ) {
                    setStarterTaskValidationError("");
                  }
                }}
                rows={1}
              />
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center gap-4">
                <div
                  className="flex items-center gap-2 text-gray-400 max-w-[9rem] sm:max-w-[11rem] cursor-pointer"
                  onClick={toggleGoalPicker}
                >
                  <div
                    className="w-3 h-3 rounded-full"
                    style={{
                      backgroundColor: selectedGoalInModal
                        ? selectedGoalInModal.color
                        : selectedGoal.color,
                    }}
                  />
                  <span className="flex-1 truncate">
                    {selectedGoalInModal
                      ? selectedGoalInModal.title
                      : selectedGoal.title}
                  </span>
                  <ChevronDown className="w-4 h-4" />
                </div>
                {screenSize.showToday && (
                  <button className="flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-md text-sm">
                    <span
                      className={
                        isToday(selectedDatePicker)
                          ? "text-green-600"
                          : "text-gray-600"
                      }
                      onClick={() => toggleDatePicker(true)}
                    >
                      {getDisplayDate(selectedDatePicker)}
                    </span>
                    <X className="w-3 h-3 text-gray-400" />
                  </button>
                )}
                <div className="relative" ref={moreMenuRef}>
                  {!screenSize.showToday && (
                    <button
                      ref={buttonRef}
                      className="text-gray-400 p-1 rounded hover:bg-gray-100"
                      onClick={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
                    >
                      <MoreHorizontal className="w-4 h-4" />
                    </button>
                  )}
                  {isMoreMenuOpen && (
                    <div
                      ref={dropdownRef}
                      className={`absolute w-48 py-1 bg-white rounded-lg shadow-lg border border-gray-200 z-10 ${
                        dropdownPosition.top
                          ? "bottom-full mb-1"
                          : "top-full mt-1"
                      } ${dropdownPosition.right ? "right-0" : "left-0"}`}
                    >
                      {!screenSize.showToday && (
                        <button
                          className="w-full px-3 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                          onClick={() => {
                            setIsMoreMenuOpen(false);
                            toggleDatePicker(true);
                          }}
                        >
                          <span
                            className={
                              isToday(selectedDatePicker)
                                ? "text-green-600"
                                : "text-gray-600"
                            }
                          >
                            {getDisplayDate(selectedDatePicker)}
                          </span>
                          <X className="w-3 h-3 text-gray-400 ml-auto" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-3">
                <button
                  className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-100 text-gray-500 hover:bg-gray-200"
                  onClick={clearDrawer}
                >
                  <X className="w-5 h-5" />
                </button>
                <button
                  className="w-10 h-10 flex items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-600"
                  onClick={handleSubmit}
                >
                  <ArrowUp className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
          {isDatePickerOpen && <DatePickerModal task={task} />}
          {isGoalPickerOpen && <GoalMove task={task} />}
        </div>
      </div>
    </div>
  );
};

const TaskDrawerWrapper = () => {
  const { isFutureTasksModal } = useFirebase();

  if (isFutureTasksModal) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 z-20">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
          <TaskDrawer />
        </div>
      </div>
    );
  }
  return <TaskDrawer />;
};

export default TaskDrawerWrapper;

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";

const colors = [
  { name: "Red", value: "#EF4444" },
  { name: "Orange", value: "#F97316" },
  { name: "Amber", value: "#F59E0B" },
  { name: "Yellow", value: "#EAB308" },
  { name: "Lime", value: "#84CC16" },
  { name: "Green", value: "#22C55E" },
  { name: "Emerald", value: "#10B981" },
  { name: "Teal", value: "#14B8A6" },
  { name: "Cyan", value: "#06B6D4" },
  { name: "Sky", value: "#0EA5E9" },
  { name: "Blue", value: "#3B82F6" },
  { name: "Indigo", value: "#6366F1" },
  { name: "Violet", value: "#8B5CF6" },
  { name: "Purple", value: "#A855F7" },
  { name: "Fuchsia", value: "#D946EF" },
  { name: "Pink", value: "#EC4899" },
];

const ColorPickerModal = ({
  isModalOpen,
  toggleModal,
  selectedColor,
  setSelectedColor,
}) => {
  return (
    <Dialog.Root open={isModalOpen} onOpenChange={toggleModal}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40" />
        <Dialog.Content className="fixed left-1/2 top-[40%] -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-[90vw] max-w-sm z-10">
          <div className="flex flex-col items-center space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">
              Choose a color
            </h2>

            <div className="grid grid-cols-4 sm:grid-cols-6 gap-4 w-full mt-2">
              {colors.map((color) => (
                <button
                  key={color.value}
                  onClick={() => setSelectedColor(color.value)}
                  title={color.name}
                  className={`w-10 h-10 sm:w-8 sm:h-8 rounded-full transition-all hover:scale-105 
                    ${
                      selectedColor === color.value
                        ? "ring-2 ring-offset-2 ring-blue-500 scale-105"
                        : ""
                    }`}
                  style={{ backgroundColor: color.value }}
                />
              ))}
            </div>

            <div className="flex justify-center w-full">
              <button
                onClick={() => {
                  toggleModal(false);
                }}
                disabled={!selectedColor}
                className="w-full max-w-[200px] py-2 px-4 bg-blue-600 text-white rounded-lg font-medium
                          disabled:bg-gray-300 disabled:cursor-not-allowed
                          hover:bg-blue-700 transition-colors"
              >
                Confirm
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ColorPickerModal;

const Sparkle2 = () => (
  <svg
    width="26"
    height="33"
    viewBox="0 0 26 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1491 1.82816C17.1777 4.7798 15.1162 10.315 13.2436 12.7886C10.9875 15.7534 5.86066 16.799 2.34402 17.688C13.6118 15.6072 12.5233 22.5335 13.4157 30.9911C13.517 24.1313 13.5824 21.8019 15.1849 19.1717C17.2852 15.7288 21.1889 15.8816 24.0437 14.0475C19.8003 14.9009 17.1737 15.972 16.1629 11.4617C15.4176 8.20929 16.6705 5.199 17.1491 1.82816Z"
      stroke="#769ae9"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export default Sparkle2;

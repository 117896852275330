import { useNavigate } from "react-router-dom";
import { signOut } from "../firebase/auth";
import { LogOut } from "lucide-react";
import { removeCookie } from "../utils";

const AccountPage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    removeCookie("hasLoggedIn");
    navigate("/join");
  };

  return (
    <div className="flex justify-center">
      <button
        onClick={handleLogout}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
      >
        <LogOut className="w-4 h-4" />
        <span>Logout</span>
      </button>
    </div>
  );
};

export default AccountPage;

import { createContext, useContext, useState } from "react";
import { auth, db } from ".";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore/lite";
import { getDate } from "../utils";
import { deleteImage, uploadImage } from "./auth";

const FirebaseContext = createContext();

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }
  return context;
};

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState("");

  const [goals, setGoals] = useState([]);
  const [isGoalPickerOpen, toggleGoalPicker] = useState(false);
  const [isFutureTasksModal, toggleFutureTasksModal] = useState(false);
  const [goalForFutureTasks, setGoalForFutureTasks] = useState(null);
  const [selectedGoalInModal, setSelectedGoalInModal] = useState(null);

  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState(null);
  const [drawerOpen, toggleDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [hasYesterdayTask, setHasYesterdayTask] = useState(false);
  const [isDatePickerOpen, toggleDatePicker] = useState(false);
  const [selectedDatePicker, setSelectedDatePicker] = useState(new Date());
  const [tasksInTheFuture, setTasksInTheFuture] = useState(null);
  const [deletionTaskId, setDeletionTaskId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showChecked, toggleShowChecked] = useState(false);

  const [wins, setWins] = useState([]);
  const [selectedWin, setSelectedWin] = useState({});
  const [isWinModalOpen, toggleWinModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isLoadingUpload, setLoadingUpload] = useState(false);
  const [croppedFile, setCroppedFile] = useState(null);

  const pullContent = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      const userDocRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setGoals(data.goals);
        setTasks(data.tasks);
        setWins(data.wins);
      }
    } catch (error) {
      console.error("Error fetching user data on refresh:", error);
    }

    setLoading(false);
  };

  const addGoalInFirebase = async (goal) => {
    // id: ID/String
    // title: String
    // color: String
    // status: String ??? maybe add in the future
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    setGoals([...goals, goal]);

    await updateDoc(userDocRef, {
      goals: arrayUnion(goal),
    });
  };

  const updateAllGoalsInFirebase = async (newGoals) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    setGoals(newGoals);

    const userDocRef = doc(db, "users", user.uid);

    try {
      // Get the current document
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      // Single operation to update the entire goals array
      await updateDoc(userDocRef, {
        goals: newGoals,
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating goal:", error);
      throw error;
    }
  };

  const updateGoalInFirebase = async (goalId, updatedGoal) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      // Get the current document
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      // Get current goals array
      const currentGoals = docSnap.data().goals;
      if (!currentGoals || !currentGoals.length) {
        throw new Error("Goals SubDocument not found");
      }

      // Create new goals array with the updated goal
      const updatedGoals = currentGoals.map((goal) =>
        goal.id === goalId
          ? {
              ...goal,
              ...updatedGoal,
            }
          : goal
      );

      setGoals(updatedGoals);

      // Single operation to update the entire goals array
      await updateDoc(userDocRef, {
        goals: updatedGoals,
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating goal:", error);
      throw error;
    }
  };

  const removeGoalInFirebase = async (goalId) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      const currentGoals = docSnap.data().goals;
      const currentTasks = docSnap.data().tasks;
      if (
        !currentGoals ||
        !currentGoals.length ||
        !currentTasks ||
        !currentTasks.length
      ) {
        throw new Error("Goals or Tasks SubDocument not found");
      }

      const updatedGoals = currentGoals.filter((goal) => goal.id !== goalId);
      const updatedTasks = currentTasks.filter(
        (task) => task.goalId !== goalId
      );

      setGoals(updatedGoals);
      setTasks(updatedTasks);

      await updateDoc(userDocRef, {
        goals: updatedGoals,
      });
      await updateDoc(userDocRef, {
        tasks: updatedTasks,
      });

      return { success: true };
    } catch (error) {
      console.error("Error removing goal:", error);
      throw error;
    }
  };

  const openDrawerWithGoal = (goalId) => {
    if (!goalId) return;

    toggleDrawer(true);

    const selectedGoal = goals.find((goal) => goal.id === goalId);

    setDrawerContent(selectedGoal);
  };

  const addTaskToFirebase = async () => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    const newTask = {
      ...task,
      id: String(new Date().getTime()),
      checked: false,
      dueOnDay: getDate(selectedDatePicker),
    };

    if (tasksInTheFuture && tasksInTheFuture["new-task"]) {
      newTask.itWasInTheFuture = tasksInTheFuture["new-task"];
    }

    // cleanup
    setTasks([...tasks, newTask]);
    setTask(null);
    toggleDrawer(false);

    // firebase
    await updateDoc(userDocRef, {
      tasks: arrayUnion(newTask),
    });
  };

  const beginDayForTasksInFirebase = async (newTasks) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    // Get the current document
    const userDocRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(userDocRef);
    if (!docSnap.exists()) throw new Error("User document not found");

    try {
      const newTasksWithDueOnDay = newTasks.map((task) => {
        delete task.itWasInTheFuture;
        return {
          ...task,
          dueOnDay: getDate(),
        };
      });

      const futureTasks = tasks.filter((task) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to start of today

        const dueOnDay = new Date(task.dueOnDay);
        dueOnDay.setHours(0, 0, 0, 0); // Set to start of today

        return dueOnDay > today;
      });

      const allTasks = [...newTasksWithDueOnDay, ...futureTasks];

      setTasks(allTasks);

      // Single operation to update the entire goals array
      await updateDoc(userDocRef, {
        tasks: allTasks,
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating goal:", error);
      throw error;
    }
  };

  const removeTaskInFirebase = async (taskId) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      const currentTasks = docSnap.data().tasks;
      if (!currentTasks || !currentTasks.length) {
        throw new Error("Tasks SubDocument not found");
      }

      const updatedTasks = currentTasks.filter((task) => task.id !== taskId);

      setTasks(updatedTasks);

      await updateDoc(userDocRef, {
        tasks: updatedTasks,
      });

      return { success: true };
    } catch (error) {
      console.error("Error removing goal:", error);
      throw error;
    }
  };

  const checkTaskInFirebase = async (taskId, updatedTask) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      // Get the current document
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      // Get current tasks array
      const currentTasks = docSnap.data().tasks;
      if (!currentTasks || !currentTasks.length) {
        throw new Error("tasks SubDocument not found");
      }

      // Create new tasks array with the updated task
      const updatedTasks = currentTasks.map((task) => {
        if (task.id === taskId) {
          return {
            ...task,
            ...updatedTask,
          };
        } else {
          return task;
        }
      });

      setTasks(updatedTasks);

      // Single operation to update the entire tasks array
      await updateDoc(userDocRef, {
        tasks: updatedTasks,
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating task:", error);
      throw error;
    }
  };

  const updateTaskInFirebase = async (taskId, updatedTask) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      // Get the current document
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      // Get current tasks array
      const currentTasks = docSnap.data().tasks;
      if (!currentTasks || !currentTasks.length) {
        throw new Error("tasks SubDocument not found");
      }

      if (tasksInTheFuture) {
        const taskInFuture = Object.entries(tasksInTheFuture).find(
          ([id, value]) => id === taskId
        );
        if (taskInFuture && taskInFuture[1]) {
          updatedTask.itWasInTheFuture = taskInFuture[1];
        }
      }

      if (selectedGoalInModal) {
        updatedTask.goalId = selectedGoalInModal.id;
      }

      // Create new tasks array with the updated task
      const updatedTasks = currentTasks.map((task) => {
        if (task.id === taskId) {
          return {
            ...task,
            ...updatedTask,
            dueOnDay: getDate(selectedDatePicker),
          };
        } else {
          return task;
        }
      });

      setTasks(updatedTasks);
      if (selectedGoalInModal) {
        setSelectedGoalInModal(null);
      }

      // Single operation to update the entire tasks array
      await updateDoc(userDocRef, {
        tasks: updatedTasks,
      });

      return { success: true };
    } catch (error) {
      console.error("Error updating task:", error);
      throw error;
    }
  };

  // win
  // id: 1,
  // date: "2024-12-20",
  // url: https...,
  // description: ""
  const updateWinsInFirebase = async (newWins) => {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(userDocRef);
    const currentWins = docSnap.data().wins;
    if (!currentWins || typeof currentWins === "undefined") {
      throw new Error("wins SubDocument not found");
    }

    const newWinsArr = [...newWins];
    newWinsArr.sort((a, b) => new Date(a.date) - new Date(b.date));

    setWins(newWinsArr);

    await updateDoc(userDocRef, { wins: newWinsArr });

    return { success: true };
  };

  const uploadAndSaveWinToFirebase = async () => {
    setLoadingUpload(true);

    const newWin =
      selectedWin && selectedWin.id
        ? { ...selectedWin }
        : {
            ...selectedWin,
            id: new Date().getTime().toString(),
            date: getDate(),
          };

    if (croppedFile) {
      const downloadURL = await uploadImage(croppedFile, newWin.id);

      if (downloadURL) {
        newWin.url = downloadURL;
      }
    }

    if (selectedWin.url && !croppedImage && !croppedFile && !uploadedImage) {
      const imageDeleted = await deleteImage(`win-${selectedWin.id}.jpg`);
      if (imageDeleted) {
        newWin.url = null;
      }
    }

    const newWins =
      selectedWin && selectedWin.id
        ? wins.map((win) =>
            win.id === selectedWin.id ? { ...win, ...newWin } : win
          )
        : [newWin, ...wins];

    setWins(newWins);
    await updateWinsInFirebase(newWins);

    cleanUploadModal();

    return { success: true };
  };

  const cleanUploadModal = () => {
    setLoadingUpload(false);
    toggleWinModal(false);
    setUploadedImage(null);
    setCroppedImage(null);
    setCroppedFile(null);
    setSelectedWin({});
  };

  const removeWinInFirebase = async (winId) => {
    setLoadingUpload(true);

    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const userDocRef = doc(db, "users", user.uid);

    try {
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) throw new Error("User document not found");

      const currentWins = docSnap.data().wins;
      if (!currentWins || !currentWins.length) {
        throw new Error("Wins SubDocument not found");
      }

      await deleteImage(`win-${winId}.jpg`);

      const updatedWins = currentWins.filter((win) => win.id !== winId);

      setWins(updatedWins);

      await updateDoc(userDocRef, {
        wins: updatedWins,
      });

      cleanUploadModal();

      return { success: true };
    } catch (error) {
      console.error("Error removing win:", error);
      throw error;
    }
  };

  const value = {
    user,
    setUser,
    loading,
    setLoading,
    loginError,
    setLoginError,

    goals,
    tasks,
    wins,
    setGoals,
    setTasks,
    setWins,
    addTaskToFirebase,
    beginDayForTasksInFirebase,
    removeTaskInFirebase,
    updateTaskInFirebase,
    checkTaskInFirebase,
    addGoalInFirebase,
    updateAllGoalsInFirebase,
    updateGoalInFirebase,
    removeGoalInFirebase,
    openDrawerWithGoal,
    toggleDrawer,
    drawerOpen,
    drawerContent,
    setDrawerContent,
    hasYesterdayTask,
    setHasYesterdayTask,
    task,
    setTask,
    isDatePickerOpen,
    toggleDatePicker,
    isGoalPickerOpen,
    toggleGoalPicker,
    selectedGoalInModal,
    setSelectedGoalInModal,
    selectedDatePicker,
    setSelectedDatePicker,
    tasksInTheFuture,
    setTasksInTheFuture,
    selectedWin,
    setSelectedWin,
    uploadAndSaveWinToFirebase,
    removeWinInFirebase,
    uploadedImage,
    setUploadedImage,
    croppedImage,
    setCroppedImage,
    isCropping,
    setIsCropping,
    croppedFile,
    setCroppedFile,
    isWinModalOpen,
    toggleWinModal,
    isLoadingUpload,
    setLoadingUpload,
    deletionTaskId,
    setDeletionTaskId,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isFutureTasksModal,
    toggleFutureTasksModal,
    goalForFutureTasks,
    setGoalForFutureTasks,
    showChecked,
    toggleShowChecked,
    pullContent,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

import React, { useEffect } from "react";
import { TaskItem } from "./today";
import { useFirebase } from "../firebase/context";
import { isFuture } from "date-fns";
import { categorizeByUniqueDates, sortByDueOnDay } from "../utils";
import { X } from "lucide-react";
import { UTILITIES_GOAL } from "../utils/constants";

const FutureTasksModal = () => {
  const {
    tasks,
    toggleFutureTasksModal,
    setGoalForFutureTasks,
    goals,
    goalForFutureTasks,
  } = useFirebase();

  const selectedGaol = [...goals, UTILITIES_GOAL].find(
    (goal) => goal.id === goalForFutureTasks
  );
  const futureTasks = tasks
    .filter(
      (task) => isFuture(task.dueOnDay) && task.goalId === goalForFutureTasks
    )
    .sort(sortByDueOnDay);
  const isEmpty = futureTasks.length === 0;

  useEffect(() => {
    if (isEmpty) {
      toggleFutureTasksModal(false);
      setGoalForFutureTasks(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmpty]);

  const daysWithTasks = categorizeByUniqueDates(futureTasks);

  const closeModal = () => {
    setGoalForFutureTasks(null);
    toggleFutureTasksModal(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-6">
          <div className="flex flex-col space-y-6">
            <div className="flex justify-between">
              <div className="text-xl font-semibold text-gray-700">
                <div>Future Tasks</div>
                <div className="text-sm font-normal text-gray-400">
                  {selectedGaol.title}
                </div>
              </div>
              <div asChild className="cursor-pointer" onClick={closeModal}>
                <X />
              </div>
            </div>

            {daysWithTasks.map((day, index) => (
              <div key={index} className="relative">
                <div className="flex items-start gap-6">
                  {/* Content */}
                  <div className="flex-1">
                    <div className="text-sm text-gray-600 mb-2">
                      {new Date(day.date).toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </div>

                    <div>
                      {day.tasks.map((task, index) => (
                        <TaskItem key={index} task={task} futureTasksModal />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FutureTasksModal;

import React, { useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { CheckCircle2, Circle } from "lucide-react";
import { useFirebase } from "../firebase/context";
import { UTILITIES_GOAL } from "../utils/constants";

const GoalModal = ({ task }) => {
  const {
    goals,
    isGoalPickerOpen,
    toggleGoalPicker,
    selectedGoalInModal,
    setSelectedGoalInModal,
  } = useFirebase();

  const updatedGoals = [...goals, UTILITIES_GOAL];

  useEffect(() => {
    const goal = updatedGoals.find(({ id }) => task.goalId === id);
    setSelectedGoalInModal(goal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog.Root open={isGoalPickerOpen} onOpenChange={toggleGoalPicker}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40 z-20" />
        <Dialog.Content className="fixed left-1/2 top-[10%] max-h-[85vh] w-[90vw] max-w-md -translate-x-1/2 z-20 rounded-lg bg-white p-6 shadow-lg focus:outline-none">
          <Dialog.Title className="text-lg font-medium">Move task</Dialog.Title>
          <Dialog.Description className="mt-2 text-sm text-gray-500">
            Select which goal you would like to move this task to.
          </Dialog.Description>

          <div className="mt-4 flex flex-col space-y-2">
            {updatedGoals.map((goal) => (
              <button
                key={goal.id}
                onClick={() => {
                  setSelectedGoalInModal(goal);
                }}
                className="flex py-3"
              >
                {selectedGoalInModal && selectedGoalInModal.id === goal.id ? (
                  <CheckCircle2 className="mr-2 h-7 w-7" stroke={goal.color} />
                ) : (
                  <Circle className="mr-2 h-7 w-7" stroke={goal.color} />
                )}
                <span className="mt-[1px] text-left flex-1">{goal.title}</span>
              </button>
            ))}
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <Dialog.Close asChild>
              <button
                className="inline-flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                onClick={() => setSelectedGoalInModal(null)}
              >
                Cancel
              </button>
            </Dialog.Close>
            <Dialog.Close asChild>
              <button
                disabled={
                  selectedGoalInModal && selectedGoalInModal.id === task.goalId
                }
                className="inline-flex items-center justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              >
                Confirm
              </button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default GoalModal;

import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { useFirebase } from "../firebase/context";
import isToday from "date-fns/isToday";

const DatePickerModal = ({ task }) => {
  const {
    isDatePickerOpen,
    toggleDatePicker,
    selectedDatePicker,
    setSelectedDatePicker,
    tasksInTheFuture,
    setTasksInTheFuture,
  } = useFirebase();
  const [currentViewDate, setCurrentViewDate] = useState(new Date());

  useEffect(() => {
    const parsedDate = new Date(
      selectedDatePicker || task.dueOnDay || new Date()
    );
    setSelectedDatePicker(parsedDate);
    setCurrentViewDate(parsedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  // Generate array of dates for the current month
  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(null); // Empty cells for days before the 1st
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }

    return days;
  };

  const navigateMonth = (direction) => {
    const newDate = new Date(currentViewDate);
    newDate.setMonth(newDate.getMonth() + direction);
    setCurrentViewDate(newDate);
  };

  const handleConfirm = () => {
    toggleDatePicker(false);

    const existingFutureTasks = tasksInTheFuture ? tasksInTheFuture : {};
    setTasksInTheFuture({
      ...existingFutureTasks,
      [task.id || "new-task"]: !isToday(selectedDatePicker),
    });
  };

  const days = getDaysInMonth(currentViewDate);
  const currentMonth = currentViewDate.toLocaleString("default", {
    month: "long",
  });
  const currentYear = currentViewDate.getFullYear();

  // Check if current view is in the future compared to today
  const today = new Date();
  const isViewingFutureMonth =
    currentViewDate.getFullYear() > today.getFullYear() ||
    (currentViewDate.getFullYear() === today.getFullYear() &&
      currentViewDate.getMonth() > today.getMonth());

  return (
    <>
      {isDatePickerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          {/* Modal Content */}
          <div className="bg-white rounded-lg p-6 w-80 shadow-xl">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800">
                Choose a date
              </h2>
              <button
                onClick={() => {
                  toggleDatePicker(false);
                  setSelectedDatePicker(
                    task.dueOnDay ? new Date(task.dueOnDay) : new Date()
                  );
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={20} />
              </button>
            </div>

            {/* Month Navigation */}
            <div className="flex justify-between items-center mb-4">
              <button
                disabled={
                  currentViewDate.getMonth() === today.getMonth() &&
                  currentViewDate.getFullYear() === today.getFullYear()
                }
                onClick={() => navigateMonth(-1)}
                className={`rounded-full w-8 h-8 -ml-1 ${
                  currentViewDate.getMonth() === today.getMonth() &&
                  currentViewDate.getFullYear() === today.getFullYear()
                    ? "text-gray-300"
                    : "hover:bg-gray-100 text-gray-700"
                }`}
              >
                ←
              </button>
              <div className="flex flex-col items-center">
                <div className="font-medium">
                  {currentMonth} {currentYear}
                </div>
                {isViewingFutureMonth && (
                  <button
                    onClick={() => {
                      setCurrentViewDate(new Date());
                    }}
                    className="text-sm text-blue-500 hover:text-blue-600 mt-1"
                  >
                    Today
                  </button>
                )}
              </div>
              <button
                onClick={() => navigateMonth(1)}
                className="hover:bg-gray-100 rounded-full w-8 h-8 -mr-1"
              >
                →
              </button>
            </div>

            {/* Calendar Grid */}
            <div className="grid grid-cols-7 gap-1 mb-4">
              {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
                <div
                  key={day}
                  className="text-center text-sm text-gray-500 py-1"
                >
                  {day}
                </div>
              ))}
              {days.map((date, index) => {
                if (!date) return <div key={index} className="invisible" />;

                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const isSameDate = (date1, date2) => {
                  return (
                    date1.getFullYear() === date2.getFullYear() &&
                    date1.getMonth() === date2.getMonth() &&
                    date1.getDate() === date2.getDate()
                  );
                };

                const isPast = date < today;
                const isToday = isSameDate(date, today);
                const isSelected = isSameDate(date, selectedDatePicker);

                return (
                  <button
                    key={index}
                    onClick={() => !isPast && setSelectedDatePicker(date)}
                    disabled={isPast}
                    className={`w-8 h-8 text-center rounded-full flex items-center justify-center ${
                      isSelected
                        ? "bg-blue-500 text-white hover:bg-blue-600"
                        : isToday && !isViewingFutureMonth
                        ? "border-2 border-blue-500 text-blue-500"
                        : isPast
                        ? ""
                        : "text-gray-700"
                    } ${
                      isPast
                        ? "text-gray-300"
                        : isSelected
                        ? ""
                        : "hover:bg-blue-100"
                    }`}
                  >
                    {date.getDate()}
                  </button>
                );
              })}
            </div>

            {/* Confirm Button */}
            <button
              onClick={handleConfirm}
              className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DatePickerModal;

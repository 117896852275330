const Sparkle1 = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.1795 1.91867C16.7984 10.5486 12.1161 12.6282 2.08338 6.30551C13.2714 11.9387 12.8515 19.6266 6.79595 28.7053C11.6523 21.1175 13.0886 19.8318 18.1351 18.9231C20.864 18.4355 26.8071 20.9813 29.4137 22.7839C24.4253 19.2456 16.646 11.9023 26.1795 1.91867Z"
      stroke="#769ae9"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export default Sparkle1;
